import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { ServiceService } from '../../../../../../core/services/service.service';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'dialog-history-refer-covid19',
    templateUrl: './dialog-history-refer-covid19.html',
})

export class DialoghistoryReferUserCovid19 {
    dataReferHistory : any;
    dataUser : any;
    dataComment : any;
    constructor(
        public dialog: MatDialog,
        private ServiceService: ServiceService,
        public dialogRef: MatDialogRef<DialoghistoryReferUserCovid19>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data);
        this.loadDataRefer(data);
    }

    loadDataRefer(data:any){
        this.dataUser = data.dataUser;
        this.dataComment = data.dataComment;
        this.ServiceService.GetdataReferHistory_(data.dataUser.userId).then(resReferHistory => {
            console.log("resReferHistory : ",resReferHistory);
            this.dataReferHistory = resReferHistory;
        });
    }

    onClickPDF(){
        // window.open("https://localhost:44316/api/CovidSymptomV2/ViewReferUsercovid19?userId=" + this.dataUser.userId);
        window.open(GlobalConstants.imageUrl1 + "/api/CovidSymptomV2/ViewReferUsercovid19?userId=" + this.dataUser.userId);
    }
}
