<section class="content-header" style="padding-bottom: 0px;">
    <div class="container-fluid">
        <div class="row">
            <div class="col-x-2 col-lg-2 col-md-2 col-sm-2 col-4">
                <h1 class="text">Start Case</h1>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-8">
                <hr>
            </div>
        </div>
    </div>
</section>

<section class="content ml-2 mr-2">

    <div class="card mat-elevation-z8">
        <div class="card-body p-0 web-design" style="margin: 10px;">
            <div class="row" *ngIf="dataSOS.dataDevice != null">
                <div class="col-xl-1 col-lg-2 col-md-2 col-sm-2 col-6"
                    style="text-align: center;font-size: 26px; margin-top: auto;
                    margin-bottom: auto;color: #3d9970;">
                    <span><i class="fas fa-wifi"></i> </span>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                    <img *ngIf="dataSOS.dataDevice.devicePic != null"
                        src="{{imageUrl2}}/{{dataSOS.dataDevice.devicePic}}"
                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                        class="img-circle elevation-2"
                        style="height: 60px; width: 60px; margin-right:
                        10px;object-fit: cover;" alt="User Image">
                    <img *ngIf="dataSOS.dataDevice.devicePic == null"
                        src="assets/adminlte/dist/img/nouser.svg"
                        class="img-circle elevation-2"
                        style="height: 60px; width: 60px; margin-right:
                        10px;object-fit: cover;" alt="User Image">
                    <span style="font-size: 16px;">
                        {{dataSOS.dataDevice.deviceName}}
                    </span>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6"
                    style="text-align: center;font-size: 26px; margin-top: auto;
                    margin-bottom: auto;">
                    <span style="font-size: 16px;"><i class="fas
                            fa-map-marker-alt"></i> {{dataSOS.location}} </span>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6"
                    style="display: flex; justify-content: center; font-size:
                    16px;margin-top: auto; margin-bottom: auto;">
                    <span>
                        <div class="row">
                            <div *ngIf="dataSOS.logSos.sos_type == 'sos'"><span
                                    class="mr-3">
                                    <mat-icon>watch</mat-icon> SOS From Watch
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type == 'covid'"><span
                                    class="mr-3">
                                    <i class="fas fa-head-side-cough"></i>
                                    Symptom Covid19
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type == 'sos-phone'"><span
                                    class="mr-3">
                                    <mat-icon>phone_android</mat-icon> SOS From
                                    Phone
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type == 'temp-fail'"><span
                                    class="mr-3">
                                    <mat-icon>watch</mat-icon> Temp Fail
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type ==
                                'heart-fail'"><span class="mr-3">
                                    <mat-icon>watch</mat-icon> Heartrate Fail
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type ==
                                'fall-detect'"><span class="mr-3">
                                    <mat-icon>watch</mat-icon> Fall
                                </span></div>

                            <div *ngIf="dataSOS.logSos.sos_type ==
                                'heartrate-detect'"><span class="mr-3">
                                    <mat-icon>watch</mat-icon> Heartrate Detect
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type ==
                                'spo2-detect'"><span class="mr-3">
                                    <mat-icon>watch</mat-icon> Spo2 Detect
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type ==
                                'bodytemp-detect'"><span class="mr-3">
                                    <mat-icon>watch</mat-icon> Bodytemp Detect
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type ==
                                'bloodpressure-detect'"><span class="mr-3">
                                    <mat-icon>watch</mat-icon> Bloodpressure
                                    Detect
                                </span></div>
                        </div>
                    </span>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6"
                    style="text-align: center;font-size: 16px;margin-top: auto;
                    margin-bottom: auto;">
                    <span class="right badge badge-danger shake"
                        style="font-size: 15px;"><i
                            class="fas fa-exclamation-triangle"></i>
                        {{dataSOS.logSos.time_log | date :'short'}}</span>
                </div>
                <div class="col-xl-1 col-lg-4 col-md-4 col-sm-4 col-6"
                    style="text-align: center;font-size: 26px; margin-top: auto;
                    margin-bottom: auto;color: #dc3545;">
                    <span> <i class="fas fa-exclamation-triangle"></i></span>
                </div>
            </div>
            <div class="row" *ngIf="dataSOS.dataUserPhone != null">
                <div class="col-xl-1 col-lg-2 col-md-2 col-sm-2 col-6"
                    style="text-align: center;font-size: 26px; margin-top: auto;
                    margin-bottom: auto;color: #3d9970;">
                    <span><i class="fas fa-wifi"></i> </span>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">

                    <img *ngIf="dataSOS.dataUserPhone.userPic != null"
                        src="{{imageUrl2}}/{{dataSOS.dataUserPhone.userPic}}"
                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                        class="img-circle elevation-2"
                        style="height: 60px; width: 60px; margin-right:
                        10px;object-fit: cover;" alt="User Image">
                    <img *ngIf="dataSOS.dataUserPhone.userPic == null"
                        src="assets/adminlte/dist/img/nouser.svg"
                        class="img-circle elevation-2" class="responsive"
                        style="height: 60px; width: 60px;"
                        alt="User Image">


                    <span style="font-size: clamp(0.8rem, 2.5vw, 1rem); ">
                        {{dataSOS.dataUserPhone.userName}}
                    </span>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12"
                    style="text-align: center;font-size: 26px; margin-top: auto;
                    margin-bottom: auto;">
                    <span style="font-size: 16px;"><i class="fas
                            fa-map-marker-alt"></i> {{dataSOS.location}} </span>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-12"
                    style="display: flex; justify-content: center; font-size:
                    16px;margin-top: auto; margin-bottom: auto;">
                    <span>
                        <div class="row">
                            <div *ngIf="dataSOS.logSos.sos_type == 'sos'"><span
                                    class="mr-3">
                                    <mat-icon>watch</mat-icon> SOS From Watch
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type == 'covid'"><span
                                    class="mr-3">
                                    <i class="fas fa-head-side-cough"></i>
                                    Symptom Covid19
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type == 'sos-phone'"><span
                                    class="mr-3">
                                    <mat-icon>phone_android</mat-icon> SOS From
                                    Phone
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type == 'temp-fail'"><span
                                    class="mr-3">
                                    <mat-icon>watch</mat-icon> Temp Fail
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type ==
                                'heart-fail'"><span class="mr-3">
                                    <mat-icon>watch</mat-icon> Heartrate Fail
                                </span></div>
                            <div *ngIf="dataSOS.logSos.sos_type ==
                                'fall-detect'"><span class="mr-3">
                                    <mat-icon>watch</mat-icon> Fall
                                </span></div>
                        </div>
                    </span>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-6"
                    style="text-align: center;font-size: 16px;margin-top: auto;
                    margin-bottom: auto;">
                    <span class="right badge badge-danger shake"
                        style="font-size: 15px;"><i
                            class="fas fa-exclamation-triangle"></i>
                        {{dataSOS.logSos.time_log | date :'short'}}</span>
                </div>
                <div class="col-xl-1 col-lg-4 col-md-4 col-sm-4 col-6"
                    style="text-align: center;font-size: 26px; margin-top: auto;
                    margin-bottom: auto;color: #dc3545;">
                    <span> <i class="fas fa-exclamation-triangle"></i></span>
                </div>
            </div>
        </div>
        <div class="card-body p-0 app-design" style="margin: 10px;">
          <ng-container *ngIf="dataSOS.dataDevice != null">
            <div class="d-flex justify-content-center">
              <div>
                <div class="position-relative">
                  <img *ngIf="dataSOS.dataDevice.devicePic != null"
                      src="{{imageUrl2}}/{{dataSOS.dataDevice.devicePic}}"
                      onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                      class="img-circle elevation-2"
                      style="height: 60px; width: 60px; margin-right:
                      10px;object-fit: cover;" alt="User Image">
                  <img *ngIf="dataSOS.dataDevice.devicePic == null"
                      src="assets/adminlte/dist/img/nouser.svg"
                      class="img-circle elevation-2"
                      style="height: 60px; width: 60px; margin-right:
                      10px;object-fit: cover;" alt="User Image">
                  <div
                    style="font-size: 18px;color: #3d9970; position: absolute; right: 0; top: 0;">
                    <span><i class="fas fa-wifi"></i> </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <p style="font-size: 16px;">
                {{dataSOS.dataDevice.deviceName}}
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <span style="font-size: 16px;"><i class="fas
                fa-map-marker-alt"></i> {{dataSOS.location}} </span>
            </div>
            <div class="d-flex justify-content-center">
              <span>
                <div class="row">
                    <div *ngIf="dataSOS.logSos.sos_type == 'sos'"><span
                            class="mr-3">
                            <mat-icon>watch</mat-icon> SOS From Watch
                        </span></div>
                    <div *ngIf="dataSOS.logSos.sos_type == 'covid'"><span
                            class="mr-3">
                            <i class="fas fa-head-side-cough"></i>
                            Symptom Covid19
                        </span></div>
                    <div *ngIf="dataSOS.logSos.sos_type == 'sos-phone'"><span
                            class="mr-3">
                            <mat-icon>phone_android</mat-icon> SOS From
                            Phone
                        </span></div>
                    <div *ngIf="dataSOS.logSos.sos_type == 'temp-fail'"><span
                            class="mr-3">
                            <mat-icon>watch</mat-icon> Temp Fail
                        </span></div>
                    <div *ngIf="dataSOS.logSos.sos_type ==
                        'heart-fail'"><span class="mr-3">
                            <mat-icon>watch</mat-icon> Heartrate Fail
                        </span></div>
                    <div *ngIf="dataSOS.logSos.sos_type ==
                        'fall-detect'"><span class="mr-3">
                            <mat-icon>watch</mat-icon> Fall
                        </span></div>

                    <div *ngIf="dataSOS.logSos.sos_type ==
                        'heartrate-detect'"><span class="mr-3">
                            <mat-icon>watch</mat-icon> Heartrate Detect
                        </span></div>
                    <div *ngIf="dataSOS.logSos.sos_type ==
                        'spo2-detect'"><span class="mr-3">
                            <mat-icon>watch</mat-icon> Spo2 Detect
                        </span></div>
                    <div *ngIf="dataSOS.logSos.sos_type ==
                        'bodytemp-detect'"><span class="mr-3">
                            <mat-icon>watch</mat-icon> Bodytemp Detect
                        </span></div>
                    <div *ngIf="dataSOS.logSos.sos_type ==
                        'bloodpressure-detect'"><span class="mr-3">
                            <mat-icon>watch</mat-icon> Bloodpressure
                            Detect
                        </span></div>
                </div>
            </span>
            </div>
            <div class="d-flex justify-content-center">
              <p class="right badge badge-danger shake"
                          style="font-size: 15px;"><i
                              class="fas fa-exclamation-triangle"></i>
                          {{dataSOS.logSos.time_log | date :'short'}}</p>
            </div>
          </ng-container>
          <ng-container *ngIf="dataSOS.dataUserPhone != null">
            <div class="d-flex justify-content-center">
              <div>
                <div class="position-relative">
                  <img *ngIf="dataSOS.dataUserPhone.userPic != null"
                        src="{{imageUrl2}}/{{dataSOS.dataUserPhone.userPic}}"
                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                        class="img-circle elevation-2"
                        style="height: 60px; width: 60px; margin-right:
                        10px;object-fit: cover;" alt="User Image">
                    <img *ngIf="dataSOS.dataUserPhone.userPic == null"
                        src="assets/adminlte/dist/img/nouser.svg"
                        class="img-circle elevation-2" class="responsive"
                        style="height: 60px; width: 60px;"
                        alt="User Image">
                  <div
                    style="font-size: 18px;color: #3d9970; position: absolute; right: 0; top: 0;">
                    <span><i class="fas fa-wifi"></i> </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <p style="font-size: 16px;">
                {{dataSOS.dataUserPhone.userName}}
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <span style="font-size: 16px;"><i class="fas
                fa-map-marker-alt"></i> {{dataSOS.location}} </span>
            </div>
            <div class="d-flex justify-content-center">
              <span>
                <div class="row">
                  <div *ngIf="dataSOS.logSos.sos_type == 'sos'"><span
                          class="mr-3">
                          <mat-icon>watch</mat-icon> SOS From Watch
                      </span></div>
                  <div *ngIf="dataSOS.logSos.sos_type == 'covid'"><span
                          class="mr-3">
                          <i class="fas fa-head-side-cough"></i>
                          Symptom Covid19
                      </span></div>
                  <div *ngIf="dataSOS.logSos.sos_type == 'sos-phone'"><span
                          class="mr-3">
                          <mat-icon>phone_android</mat-icon> SOS From
                          Phone
                      </span></div>
                  <div *ngIf="dataSOS.logSos.sos_type == 'temp-fail'"><span
                          class="mr-3">
                          <mat-icon>watch</mat-icon> Temp Fail
                      </span></div>
                  <div *ngIf="dataSOS.logSos.sos_type ==
                      'heart-fail'"><span class="mr-3">
                          <mat-icon>watch</mat-icon> Heartrate Fail
                      </span></div>
                  <div *ngIf="dataSOS.logSos.sos_type ==
                      'fall-detect'"><span class="mr-3">
                          <mat-icon>watch</mat-icon> Fall
                      </span></div>
              </div>
            </span>
            </div>
            <div class="d-flex justify-content-center">
              <p class="right badge badge-danger shake"
                        style="font-size: 15px;"><i
                            class="fas fa-exclamation-triangle"></i>
                        {{dataSOS.logSos.time_log | date :'short'}}</p>
            </div>
          </ng-container>
        </div>
    </div>
</section>

<section class="content mr-2 ml-2">
    <div class="card-body p-0">
        <div class="row">
            <div class="col-md-5" *ngIf="checkLog == true">
                <mat-card class="mat-elevation-z8" style="height: 370px;">
                    <canvas baseChart [datasets]="lineChartData"
                        [labels]="lineChartLabels" [options]="lineChartOptions"
                        [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType"
                        [plugins]="lineChartPlugins">
                    </canvas>
                </mat-card>
            </div>
            <div class="col-md-5" *ngIf="checkLog == false">
                <mat-card class="mat-elevation-z8">
                    <!-- <div class="row" style="height: 397px;text-align: center;">
                        <div class="col-md-12"
                            style="margin-top: auto;margin-bottom: auto;color: #bdbbb7;font-size: 18px;">
                            <i class="fas fa-chart-area"></i>
                            <p>No Log Heartrate</p>
                        </div>
                    </div> -->
                    <div class="row" style="height: 397px;">
                        <div class="col-md-12" style="margin: 12px;">
                            <div style="text-align: left;padding-bottom: 20px;"><strong
                                    style="font-size: 22px;"><img
                                        src="assets/adminlte/dist/img/smart-watch.svg"
                                        width="25px" height="25px" alt="">
                                    Connect Watch</strong></div>
                            <div *ngFor="let item of dataConnectWatch">
                                    <!-- <div *ngIf="item.devicePrimary" class="row hover-select" style="margin-left: 0px;margin-right: 0px; padding-top: 10px;padding-bottom: 10px"
                                    (click)="openPersonalHealth(item.deviceSerial)"> -->
                                    <a target="_blank" href="{{reportUrl}}/auth?deviceImei={{item.deviceSerial}}&sysUserId={{idOperator}}&report=true" *ngIf="item.devicePrimary" class="hover-select" style="margin-left: 0px;margin-right: 0px; padding-top: 10px;padding-bottom: 10px; text-decoration: none; color: #000;">
                                        <div class="row">
                                            <div class="col-5">
                                                <span>
                                                    <img class="img-circle ml-2"
                                                        src="{{imageUrl2}}/{{ item.devicePic }}"
                                                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                        style="width: 40px;height: 40px;
                                                        object-fit: cover;" alt="User profile
                                                        picture">
                                                </span>
                                                <span>
                                                    Name : <b>{{ item.deviceName }}</b>
                                                </span>
                                            </div>
                                            <div class="col-7">
                                                    <span>Owner Contact : </span><span class="font-weight-bold">{{ dataSOS.dataUserPhone.userPhone }}</span>
                                                    <br><span>Emergency Contact 1 : </span><span class="font-weight-bold">{{ item.devicePhone }}</span>
                                                    <br><span>Contact Name 1 : </span><span class="font-weight-bold">{{ item.emergency && item.emergency.devicePhoneName ? item.emergency.devicePhoneName : "-" }}</span>
                                                    <br><span>Emergency Contact 2 : </span><span class="font-weight-bold">{{ item.devicePhone2 }}</span>
                                                    <br><span>Contact Name 2 : </span><span class="font-weight-bold">{{ item.emergency && item.emergency.devicePhoneName2 ? item.emergency.devicePhoneName2 : "-" }}</span>
                                            </div>
                                        </div>
                                    </a>
                                <hr style="margin-top: 0px;margin-bottom:0px;">
                            </div>
                        </div>
                    </div>


                </mat-card>
            </div>
            <div class="col-md-7 " *ngIf="dataSOS.dataDevice != null">
                <div class="card card-widget widget-user-2 mat-elevation-z8">
                    <div class="row">
                        <div class="col-12 col-xl-5 col-lg-5 col-md-5">
                          <div class="d-flex justify-content-center justify-content-xl-start justify-content-lg-start justify-content-md-start">
                            <div class="widget-user-header ">
                                <div class="widget-user-image">
                                    <img *ngIf="dataSOS.dataDevice.devicePic !=
                                        null"
                                        src="{{imageUrl2}}/{{dataSOS.dataDevice.devicePic}}"
                                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                        class="img-circle elevation-2"
                                        style="height: 60px; width: 60px;
                                        margin-right: 10px;object-fit: cover;"
                                        alt="User Image">
                                    <img *ngIf="dataSOS.dataDevice.devicePic ==
                                        null"
                                        src="assets/adminlte/dist/img/nouser.svg"
                                        class="img-circle elevation-2"
                                        style="height: 60px; width: 60px;
                                        margin-right: 10px;object-fit: cover;"
                                        alt="User Image">
                                </div>
                                <h3 class="widget-user-username">{{dataSOS.dataDevice.deviceName}}</h3>
                                <h5 class="widget-user-desc">{{dataSOS.logSos.time_log
                                    | date :'short'}}</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-xl-2 col-lg-2 col-md-2">
                          <div *ngIf="dataSOS.logSos.sos_type == 'fall-detect' && dataSOS.dataJob.fallMistake >= 3" class="fall-yes text-center mt-4">SOS Fall : Yes ({{dataSOS.dataJob.fallMistake}})</div>
                        </div>
                        <div class="col-12 col-xl-5 col-lg-5 col-md-5">
                            <div class="input-group d-flex justify-content-center justify-content-xl-end justify-content-lg-end justify-content-md-end mt-4 pr-4">
                                <!-- From Watch -->
                                <a target="_blank" href="{{reportUrl}}/auth?deviceImei={{dataSOS.logSos.watch_imeicode}}&sysUserId={{theUsername}}&report=true" *ngIf="allowPdf" (click)="oneTimePdf()" class="btn btn-primary mr-2"><i class="fas fa-file"></i> Export PDF</a>
                                <button class="btn btn-outline-primary mr-2" (click)="openRequestPdf()" *ngIf="!allowPdf"><i class="fas fa-file"></i> Request PDF</button>
                                <!-- <a *ngIf="dataSOS.logSos.sos_type == 'heartrate-detect' || dataSOS.logSos.sos_type == 'sos' || dataSOS.logSos.sos_type == 'spo2-detect' || dataSOS.logSos.sos_type == 'bodytemp-detect' || dataSOS.logSos.sos_type == 'bloodpressure-detect' || dataSOS.logSos.sos_type == 'fall-detect' && getSymptomsId != ''" href="{{doctorUrl}}/Personal_Health/byDeviceImei.html?deviceImei={{getSymptomsId}}&disableChart=no&sysUserId={{theUsername}}" target="_blank" class="btn btn-outline-secondary mr-2"><i class="fas fa-user-alt"></i> View Personal Information</a> -->
                                <a href="http://maps.google.com/maps?q=loc:{{dataSOS.logSos.lat}},{{dataSOS.logSos.lng}}" target="_blank" class="btn btn-success"><img  src="https://www.beemortar.com/wp-content/uploads/2021/04/kisspng-responsive-web-design-social-media-google-maps-com-map-icon-5abfaf382c12a9.8640671315225116721805.png" width="25px" height="25px"> Google Map</a>
                            </div>
                        </div>
                    </div>
                    <hr style="margin-top: 0px;margin-bottom: 0px;">
                    <div class="row">
                        <div class="col-sm-3 col-3 border-right">
                            <div class="description-block">
                                <i class="fas fa-heartbeat" style="font-size:
                                    50px;"></i>
                                <h5 class="description-header">{{dataSOS.logSos.heartrate_val}}</h5>
                            </div>
                        </div>
                        <!-- <div class="col-sm-2 col-2 border-right">
                            <div class="description-block">
                                <i class="fas fa-battery-half" style="font-size:
                                    50px;"></i>
                                <h5 class="description-header">-</h5>
                            </div>
                        </div> -->
                        <div class="col-sm-3 col-3 border-right">
                            <div class="description-block">
                                <i class="fas fa-thermometer-three-quarters"
                                    style="font-size: 50px;"></i>
                                <h5 class="description-header">{{dataSOS.logSos.temperature_val}}</h5>
                            </div>
                        </div>
                        <div class="col-sm-2 col-2 border-right">
                            <div class="description-block">
                                <img src="assets/adminlte/dist/img/spo2.png"
                                    style="width: 50px;">
                                <h5 class="description-header">{{spo2}}</h5>
                            </div>
                        </div>
                        <div class="col-sm-4 col-4">
                            <div class="description-block">
                                <img src="assets/adminlte/dist/img/pressure.png"
                                    style="width: 50px;">
                                <h5 class="description-header" *ngIf="pressure
                                    != undefined">Sbp:
                                    {{pressure.blood_pressure_SBP}} - Dbp:
                                    {{pressure.blood_pressure_DBP}}</h5>
                                <h5 class="description-header" *ngIf="pressure
                                    == undefined">Sbp: - ,Dbp: -</h5>
                            </div>
                        </div>
                    </div>
                    <div class="invoice">
                        <div class="row" style="padding-top: 10px;padding-left:
                            10px;">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12
                                col-12">
                                <h4 style="margin: 0px;">
                                    <i class="fas fa-address-book"></i> Personal
                                    profile
                                </h4>
                            </div>
                        </div>
                        <div class="row invoice-info"
                            style="margin-left: 10px;margin-right:
                            10px;margin-top: 10px;margin-bottom: 17px;">
                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4
                                col-5 invoice-col">
                                <address>
                                    <strong style="margin-bottom: 3px;"><i
                                            class="fas fa-user-alt"></i>
                                        Profile</strong><br>
                                    <b>Email:</b> -<br>
                                    <b>Name:</b>
                                    {{dataSOS.dataDevice.deviceName}}<br>
                                    <b>Gender:</b>
                                    {{dataSOS.dataDevice.deviceSex}}<br>
                                </address>
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4
                                col-3 invoice-col">
                                <address>
                                    <br>
                                    <b>Date:</b> - <br>
                                    <b>Weight:</b>
                                    {{dataSOS.dataDevice.deviceWeight}}<br>
                                    <b>Height:</b>
                                    {{dataSOS.dataDevice.deviceHeight}}<br>
                                    <b>Bloodtype:</b>
                                    {{dataSOS.dataDevice.deviceBloodtype}}
                                </address>
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4
                                col-4 invoice-col">
                                <address>
                                    <br>
                                    <b>Owner Contact:</b>
                                    {{dataSOS.accountPhoneByDevice.userPhone}}
                                    <b>Emergency Contact 1:</b>
                                    {{dataSOS.dataDevice.devicePhone}}<br>
                                    <b>Contact Name 1:</b>
                                    {{dataSOS.emergency.devicePhoneName ? dataSOS.emergency.devicePhoneName : "-"}}<br>
                                    <b>Emergency Contact 2:</b>
                                    {{dataSOS.dataDevice.devicePhone2}}<br>
                                    <b>Contact Name 2:</b>
                                    {{dataSOS.emergency.devicePhoneName2 ? dataSOS.emergency.devicePhoneName2 : "-"}}<br>
                                </address>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4
                                col-6 invoice-col">
                                <address>
                                    <strong><i class="fas fa-building"></i>
                                        Address Profile</strong><br>
                                    <b>Address:</b>
                                    {{dataSOS.dataDevice.deviceAddress}}<br>
                                    <b>SubDistrict:</b>
                                    {{dataSOS.dataDevice.deviceSubdistrict}}<br>
                                    <b>District:</b>
                                    {{dataSOS.dataDevice.deviceDistrict}}
                                </address>
                            </div>
                            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4
                                col-6 invoice-col">
                                <address>
                                    <br>
                                    <b>Province:</b>
                                    {{dataSOS.dataDevice.deviceProvince}}<br>
                                    <b>Postalcode:</b>
                                    {{dataSOS.dataDevice.userPostalcode}}
                                </address>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 " *ngIf="dataSOS.dataUserPhone != null">
                <div class="card card-widget widget-user-2 mat-elevation-z8">
                    <mat-tab-group>
                        <mat-tab label="Profile">
                            <div class="col-md-12" style="height: 380px;">
                                <div class="widget-user-header">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-5">
                                                <div class="widget-user-image">
                                                    <img
                                                        *ngIf="dataSOS.dataUserPhone.userPic
                                                        != null"
                                                        src="{{imageUrl2}}/{{dataSOS.dataUserPhone.userPic}}"
                                                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                                        class="img-circle
                                                        elevation-2"
                                                        style="height: 60px;
                                                        width: 60px;
                                                        margin-right:
                                                        10px;object-fit: cover;"
                                                        alt="User Image">
                                                    <img
                                                        *ngIf="dataSOS.dataUserPhone.userPic
                                                        == null"
                                                        src="assets/adminlte/dist/img/nouser.svg"
                                                        class="img-circle
                                                        elevation-2"
                                                        style="height: 60px;
                                                        width: 60px;
                                                        margin-right:
                                                        10px;object-fit: cover;"
                                                        alt="User Image">

                                                </div>
                                                <h3
                                                    class="widget-user-username">{{dataSOS.dataUserPhone.userName}}</h3>
                                                <h5 class="widget-user-desc">{{dataSOS.logSos.time_log
                                                    | date :'short'}}
                                                </h5>
                                            </div>
                                            <div class="col-2">
                                              <div *ngIf="dataSOS.logSos.sos_type == 'fall-detect' && dataSOS.dataJob.fallMistake >= 3" class="fall-yes text-center mt-4">SOS Fall : Yes ({{dataSOS.dataJob.fallMistake}})</div>
                                            </div>
                                            <div class="col-5"
                                                style="text-align: end;
                                                margin-top: 0;margin-bottom:
                                                auto;">
                                                <div class="input-group d-flex
                                                justify-content-end mt-1">
                                                <!-- From Phone -->
                                                    <!-- <a
                                                        *ngIf="dataSOS.logSos.sos_type
                                                        == 'sos-phone'"
                                                        href="{{doctorUrl}}/Personal_Health/getdatabyuserid.html?userid={{dataSOS.dataUserPhone.userId}}&sysUserId={{theUsername}}"
                                                        target="_blank"
                                                        class="btn
                                                        btn-outline-secondary
                                                        mr-2"><i class="fas
                                                            fa-user-alt"></i>
                                                        View Personal
                                                        Information</a> -->
                                                        <a target="_blank" href="{{reportUrl}}/auth?deviceImei={{dataSOS.logSos.watch_imeicode}}&sysUserId={{theUsername}}&report=true" *ngIf="allowPdf" (click)="oneTimePdf()" class="btn btn-primary mr-2"><i class="fas fa-file"></i> Export PDF</a>
                                                        <button class="btn btn-outline-primary mr-2" (click)="openRequestPdf()" *ngIf="!allowPdf"><i class="fas fa-file"></i> Request PDF</button>
                                                    <a
                                                        href="http://maps.google.com/maps?q=loc:{{dataSOS.logSos.lat}},{{dataSOS.logSos.lng}}"
                                                        target="_blank"
                                                        class="btn
                                                        btn-success"><img
                                                            src="https://www.beemortar.com/wp-content/uploads/2021/04/kisspng-responsive-web-design-social-media-google-maps-com-map-icon-5abfaf382c12a9.8640671315225116721805.png"
                                                            width="25px"
                                                            height="25px">
                                                        Google Map</a>
                                                    <!-- <button mat-mini-fab
                                                        class="tada mt-1"
                                                        color="primary"
                                                        aria-label="Example icon
                                                        button with a menu icon"
                                                        (click)="openDetailSosLogBody(dataSOS.dataUserPhone.userId)">
                                                        <mat-icon> insert_chart</mat-icon>
                                                    </button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <hr style="margin-top: 0px;margin-bottom: 0px;"> -->
                                <div class="row border-bottom border-top">
                                    <div class="col-sm-3 col-3 border-right">
                                        <div class="description-block">
                                            <i class="fas fa-heartbeat"
                                                style="font-size: 50px;"></i>
                                            <h5 class="description-header">{{dataSOS.logSos.heartrate_val}}</h5>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-2 col-2 border-right">
                                        <div class="description-block">
                                            <i class="fas fa-battery-half"
                                                style="font-size: 50px;"></i>
                                            <h5 class="description-header">-</h5>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-3 col-3 border-right">
                                        <div class="description-block">
                                            <i class="fas
                                                fa-thermometer-three-quarters"
                                                style="font-size: 50px;"></i>
                                            <h5 class="description-header">{{dataSOS.logSos.temperature_val}}</h5>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-3 border-right">
                                        <div class="description-block">
                                            <img
                                                src="assets/adminlte/dist/img/spo2.png"
                                                style="width: 50px;">
                                            <h5 class="description-header">{{spo2}}</h5>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-3">
                                        <div class="description-block">
                                            <img
                                                src="assets/adminlte/dist/img/pressure.png"
                                                style="width: 50px;">
                                            <h5 class="description-header"
                                                *ngIf="pressure != undefined">Sbp:
                                                {{pressure.blood_pressure_SBP}}
                                                - Dbp:
                                                {{pressure.blood_pressure_DBP}}</h5>
                                            <h5 class="description-header"
                                                *ngIf="pressure == undefined">Sbp:
                                                - ,Dbp: -</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="row" style="padding-top:
                                        10px;padding-left: 10px;">
                                        <div class="col-12">
                                            <h4 style="margin: 0px;">
                                                <i class="fas fa-address-book"></i>
                                                Personal profile
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="row invoice-info"
                                        style="margin-left: 10px;margin-right:
                                        10px;margin-top: 10px;margin-bottom:
                                        17px;">
                                        <div class="col-xl-3 col-lg-4 col-md-4
                                            col-sm-4 col-5 invoice-col">
                                            <address>
                                                <strong style="margin-bottom:
                                                    3px;"><i class="fas
                                                        fa-user-alt"></i>
                                                    Profile</strong><br>
                                                <b>Email:</b> -<br>
                                                <b>Name:</b>
                                                {{dataSOS.dataUserPhone.userName}}<br>
                                                <b>Gender:</b>
                                                {{dataSOS.dataUserPhone.userSex}}<br>
                                            </address>
                                        </div>
                                        <div class="col-xl-2 col-lg-4 col-md-4
                                            col-sm-4 col-3 invoice-col">
                                            <address>
                                                <br>
                                                <b>Date:</b> - <br>
                                                <b>Weight:</b>
                                                {{dataSOS.dataUserPhone.userWeight}}<br>
                                                <b>Height:</b>
                                                {{dataSOS.dataUserPhone.userHeight}}<br>
                                            </address>
                                        </div>
                                        <div class="col-xl-2 col-lg-4 col-md-4
                                            col-sm-4 col-4 invoice-col">
                                            <address>
                                                <br>
                                                <b>Bloodtype:</b>
                                                {{dataSOS.dataUserPhone.userBloodtype}}<br>
                                                <b>Owner contact:</b>
                                                {{dataSOS.dataUserPhone.userPhone}}
                                                <!-- <b>Phone2:</b> {{dataSOS.dataUserPhone.devicePhone2}} -->
                                            </address>
                                        </div>
                                        <div class="col-xl-3 col-lg-4 col-md-4
                                            col-sm-4 col-6 invoice-col">
                                            <address>
                                                <strong><i class="fas
                                                        fa-building"></i>
                                                    Address Profile</strong><br>
                                                <b>Address:</b>
                                                {{dataSOS.dataUserPhone.userAddress}}<br>
                                                <b>SubDistrict:</b>
                                                {{dataSOS.dataUserPhone.userSubdistrict}}<br>
                                                <b>District:</b>
                                                {{dataSOS.dataUserPhone.userDistrict}}
                                            </address>
                                        </div>
                                        <div class="col-xl-2 col-lg-4 col-md-4
                                            col-sm-4 col-6 invoice-col">
                                            <address>
                                                <br>
                                                <b>Province:</b>
                                                {{dataSOS.dataUserPhone.userProvince}}<br>
                                                <b>Postalcode:</b>
                                                {{dataSOS.dataUserPhone.userPostalcode}}
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Drug Allergy">
                            <div class="col-md-12" style="height: 380px;">
                                <div class="row" style="padding: 20px;">
                                    <div class="col-xl-3 col-lg-12 col-md-12
                                        col-sm-12 col-12"
                                        style="text-align:
                                        center;padding-bottom: 10px;">
                                        <img class="swing"
                                            src="assets\adminlte\dist\img\medical-record.png"
                                            alt="icon drugs" style="width:
                                            170px;">
                                    </div>
                                    <div *ngIf="checkDrugAllergyUser == false"
                                        class="col-md-9"
                                        style="text-align: center;margin-top:
                                        126px;color: #a0a0a0;">
                                        <i class="far fa-calendar-times"
                                            style="font-size: 60px;"></i>
                                        <p>No Information</p>
                                    </div>
                                    <div *ngIf="checkDrugAllergyUser == true"
                                        class="col-xl-9 col-lg-12 col-md-12
                                        col-sm-12 col-12">
                                        <div class="card-comment">
                                            <div style="overflow: auto;
                                                height:340px;">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Subject</th>
                                                            <th scope="col">Detail</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>โรคประจำตัว</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pCongenitalDisease}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>ยาที่ทานอยู่ประจำ</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pRegularMedication}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>ยาที่แพ้</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pAllergicDrug}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">4</th>
                                                            <td>วันที่ทราบผลติดเชื้อ</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pDateInfection}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">5</th>
                                                            <td>วันที่เริ่มมีอาการ</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pOnsetDate}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">6</th>
                                                            <td>อาการเริ่มต้น</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pInitSymptom}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">7</th>
                                                            <td>ยาที่ทานมาก่อน</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pBeforeMedicine}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">8</th>
                                                            <td>อาการ ณ ปัจจุบัน</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pCurrentSymptiom}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">9</th>
                                                            <td>อาการข้างเคียง</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pSideEffect}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">10</th>
                                                            <td>อายุครรภ์</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pAgePregnant}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">11</th>
                                                            <td>อายุเด็ก</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pAgeChild}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">12</th>
                                                            <td>ประจำเดือน</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pMenstruation}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">13</th>
                                                            <td>โรงพยาบาลต้นสังกัด</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pAffHospital}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">14</th>
                                                            <td>อื่นๆ</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                != undefined">{{dataDrugAllergyUser[0].pOther}}</td>
                                                            <td
                                                                *ngIf="dataDrugAllergyUser
                                                                == undefined">-</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="content ml-2 mr-2 mb-2">
    <div class="row">
        <div class="col-md-7 ">
            <agm-map [latitude]='lat' [longitude]='long' [zoom]='zoom'>
                <agm-marker *ngFor="let marker of markers"
                    [latitude]="marker.lat" [longitude]="marker.lng"
                    [label]="marker.title1">
                    <agm-info-window #infoWindow>
                        {{ marker.label }}
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
        <div class="col-md-5 scroll mb-2">
            <mat-card class="mat-elevation-z8">
                <div class="card-header" style="padding: 0px;">
                    <h3 style="padding-bottom: 0px;">Note</h3>
                </div>
                <br>
                <form [formGroup]="commentForm">
                    <mat-form-field appearance="outline" style="width:
                        -webkit-fill-available">
                        <mat-label>ระบุเบอร์ที่โทรกลับไป และชื่อผู้รับสาย (หากไม่รับสาย ระบุ ไม่รับสาย)</mat-label>
                        <textarea matInput rows="2" formControlName="field1"
                        [(ngModel)]="field1"></textarea>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="width:
                        -webkit-fill-available">
                        <mat-label>ระบุเบอร์โทรที่สามารถติดต่อได้ พร้อมชื่อผู้รับสาย (ถ้าติดต่อได้แล้ว ก็ระบุว่าติดต่อเบอร์เจ้าของนาฬิกาแล้ว)</mat-label>
                        <textarea matInput rows="2" formControlName="field2"
                        [(ngModel)]="field2"></textarea>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="width:
                        -webkit-fill-available">
                        <mat-label>ระบุสาเหตุการเรียก SOS และอาการที่ได้รับแจ้งอย่างละเอียด</mat-label>
                            <textarea matInput rows="6"  formControlName="field3"
                            [(ngModel)]="field3"></textarea>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="width:
                    -webkit-fill-available">
                        <mat-label>เรียกรถฉุกเฉินหรือไม่</mat-label>
                        <mat-select formControlName="field4" [(ngModel)]="field4">
                          <mat-option value="เรียกรถฉุกเฉิน">เรียกรถฉุกเฉิน</mat-option>
                          <mat-option value="ไม่เรียกรถฉุกเฉิน">ไม่เรียกรถฉุกเฉิน </mat-option>
                        </mat-select>
                      </mat-form-field>
                    <mat-form-field appearance="outline" style="width:
                        -webkit-fill-available">
                        <mat-label>พิมพ์รายละเอียดสรุปการทำงานที่เกิดขึ้น</mat-label>
                        <textarea matInput rows="2" formControlName="field5"
                            [(ngModel)]="field5"></textarea>
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="width:
                        -webkit-fill-available;">
                        <mat-label>อืนๆ</mat-label>
                        <textarea matInput rows="2" formControlName="commentJob" ></textarea>
                    </mat-form-field>
                    <button class="mr-2" mat-raised-button color="primary"
                        type="submit" style="width: 180px;"
                        (click)="addCommentJob()"
                        [disabled]="field1 == '' || field2 == '' || field3 == '' || field4 == '' || field5 == ''"><i
                            class="far fa-comment"></i> Comment</button>
                    <button mat-raised-button color="warn" style="width: 126px;" class="ml-2"
                    (click)="forceEndJob()"><i class="far fa-calendar-check"></i>
                    Force End</button>
                </form>
                <br>
                <br>
                <button mat-raised-button color="accent" style="width: 126px;"
                    disabled="{{buttonDisabledEndJob}}"
                    (click)="updateStatus()"><i class="far fa-calendar-check"></i>
                    End Job</button>
                <hr>
                <div *ngIf="dataComments !=null && dataDetailAdmin != null">
                    <div *ngIf="dataComments.length> 0" class="card-footer
                        card-comments mt-3">
                        <div *ngFor="let dataComment of dataComments">
                            <div class="card-comment">
                                <div
                                    *ngIf="dataDetailAdmin.operatorPic != null
                                    && dataDetailAdmin.operatorPic != ''; else
                                    elseImg">
                                    <img
                                        src="{{imageUrl1}}/{{dataDetailAdmin.operatorPic}}"
                                        onerror="this.src='assets/adminlte/dist/img/nouser.svg'"
                                        class="img-circle elevation-2"
                                        alt="img-profile"
                                        style="width: 35px;height:
                                        35px;object-fit: cover;">
                                </div>
                                <ng-template #elseImg>
                                    <img
                                        src="assets/adminlte/dist/img/nouser.svg"
                                        class="img-circle elevation-2"
                                        alt="img-profile" style="width:
                                        35px;height: 35px;object-fit: cover;">
                                </ng-template>
                                <div class="comment-text">
                                    <span class="username">
                                        {{dataDetailAdmin.operatorFirstname}}
                                        {{dataDetailAdmin.operatorLastname}}
                                        <span class="text-muted float-right"><i
                                                class="fas fa-clock"></i>
                                            {{dataComment.ojobTaskDate |
                                            date:'medium'}}</span>
                                    </span><!-- /.username -->
                                    <b>ระบุเบอร์ที่โทรกลับไป:</b><br>
                                    &nbsp;&nbsp;&nbsp;-{{dataComment.ojobTaskField1}}<br>
                                    <b>ระบุเบอร์โทรที่สามารถติดต่อได้:</b><br>
                                    &nbsp;&nbsp;&nbsp;-{{dataComment.ojobTaskField2}}<br>
                                    <b>ระบุสาเหตุการเรียก SOS และอาการที่ได้รับแจ้งอย่างละเอียด:</b><br>
                                    &nbsp;&nbsp;&nbsp;-{{dataComment.ojobTaskField3}}<br>
                                    <b>เรียกรถฉุกเฉินหรือไม่:</b><br>
                                    &nbsp;&nbsp;&nbsp;-{{dataComment.ojobTaskField4}}<br>
                                    <b>พิมพ์รายละเอียดสรุปการทำงานที่เกิดขึ้น:</b><br>
                                    &nbsp;&nbsp;&nbsp;-{{dataComment.ojobTaskField5}}<br>
                                    <b>อื่นๆ:</b><br>
                                    &nbsp;&nbsp;&nbsp;-{{dataComment.ojobTaskDetail}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</section>
