import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ServiceService } from 'src/app/core/services/service.service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'app-sleep',
  templateUrl: './sleep.component.html',
  styleUrls: ['./sleep.component.scss']
})
export class SleepComponent implements OnInit {

  selectedDateForm: FormGroup;
  @Input() deviceId: any;
  @ViewChild('sleepChart', { static: false }) sleepChart!: ElementRef;
  @ViewChild('deepSleepChart', { static: false }) deepSleepChart!: ElementRef;
  @ViewChild('lightSleepChart', { static: false }) lightSleepChart!: ElementRef;
  @ViewChild('goodSleepChart', { static: false }) goodSleepChart!: ElementRef;
  @ViewChild('theSleepChart', { static: false }) theSleepChart!: ElementRef;
  sleepData: any = [];
  deepSleepData: any = [];
  lightSleepData: any = [];
  goodSleepData: any = [];
  theSleepData: any = [];

  constructor(
    private fb: FormBuilder,
    @Inject(LOCALE_ID) private locale: string,
    private serviceService: ServiceService,
    private changeDetectRef:ChangeDetectorRef,
    private datePipe: DatePipe
  ) {
    this.selectedDateForm = this.createSelectedDateForm();
  }

  ngOnInit(): void {
    var dataStart: string = this.selectedDateForm.value.start;
    var dataEnd: string = this.selectedDateForm.value.end;
    var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
    var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);
    var newStart = new Date(dataStart);
    var newStartAddDay = newStart.setDate(newStart.getDate() - 1);
    dataStart_ = formatDate(newStartAddDay, 'yyyy-MM-dd', this.locale);
    var newEnd = new Date(dataEnd);
    var newEndAddDay = newEnd.setDate(newEnd.getDate() + 1);
    dataEnd_ = formatDate(newEndAddDay, 'yyyy-MM-dd', this.locale);
    this.getSleepData(dataStart_, dataEnd_);
  }

  createSelectedDateForm() {
    return this.fb.group({
      start: new FormControl(new Date()),
      end: new FormControl(new Date())
    });
  }

  getSleepData(start: string, end: string) {
    this.sleepData = [];
    this.deepSleepData = [];
    this.lightSleepData = [];
    this.goodSleepData = [];
    this.serviceService.GetSleepList_(this.deviceId).then((res) => {
      if (res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          var trueDate: Date = new Date(res[i].endTime != 'NaN' ? (res[i].endTime.length == 10 ? Number(res[i].endTime) * 1000 : Number(res[i].endTime)) : res[i].time_log);
          if (res[i].endTime == 'NaN') trueDate.setHours(trueDate.getHours() - 7);
          var mainText: string = Number(Number(res[i].allSleep / 60).toFixed(2).toString().split(".")[0]).toString();
          var decimalText: string = ((Number(Number(res[i].allSleep / 60).toFixed(2).toString().split(".")[1]) / 100) * 60).toFixed(0).toString();
          var mergedText: string = mainText + " Hr " + decimalText + " Min";
          this.sleepData.push({date: this.datePipe.transform(trueDate, "yyyy-MM-dd HH:mm"), allSleep: Number(res[i].allSleep / 60).toFixed(2), text: mergedText});
          var mainTextDeep: string = Number(Number(res[i].deepSleep / 60).toFixed(2).toString().split(".")[0]).toString();
          var decimalTextDeep: string = ((Number(Number(res[i].deepSleep / 60).toFixed(2).toString().split(".")[1]) / 100) * 60).toFixed(0).toString();
          var mergedTextDeep: string = mainTextDeep + " Hr " + decimalTextDeep + " Min";
          this.deepSleepData.push({date: this.datePipe.transform(trueDate, "yyyy-MM-dd HH:mm"), deepSleep: Number(res[i].deepSleep / 60).toFixed(2), text: mergedTextDeep});
          var mainTextLight: string = Number(Number(res[i].lightSleep / 60).toFixed(2).toString().split(".")[0]).toString();
          var decimalTextLight: string = ((Number(Number(res[i].lightSleep / 60).toFixed(2).toString().split(".")[1]) / 100) * 60).toFixed(0).toString();
          var mergedTextLight: string = mainTextLight + " Hr " + decimalTextLight + " Min";
          this.lightSleepData.push({date: this.datePipe.transform(trueDate, "yyyy-MM-dd HH:mm"), lightSleep: Number(res[i].lightSleep / 60).toFixed(2), text: mergedTextLight});
          var mainTextGood: string = Number(Number(res[i].rem / 60).toFixed(2).toString().split(".")[0]).toString();
          var decimalTextGood: string = ((Number(Number(res[i].rem / 60).toFixed(2).toString().split(".")[1]) / 100) * 60).toFixed(0).toString();
          var mergedTextGood: string = mainTextGood + " Hr " + decimalTextGood + " Min";
          this.goodSleepData.push({date: this.datePipe.transform(trueDate, "yyyy-MM-dd HH:mm"), goodSleep: Number(res[i].rem / 60).toFixed(2), text: mergedTextGood});
          this.theSleepData.push({date: this.datePipe.transform(trueDate, "yyyy-MM-dd HH:mm"), allSleep: Number(res[i].allSleep / 60).toFixed(2), allSleepText: mergedText, deepSleep: Number(res[i].deepSleep / 60).toFixed(2), deepSleepText: mergedTextDeep, lightSleep: Number(res[i].lightSleep / 60).toFixed(2), lightSleepText: mergedTextLight, goodSleep: Number(res[i].rem / 60).toFixed(2), goodSleepText: mergedTextGood});
        }
        this.sleepData = this.sleepData.filter((row: any) => Date.parse(start) <= Date.parse(row.date) && Date.parse(end) >= Date.parse(row.date));
        this.deepSleepData = this.deepSleepData.filter((row: any) => Date.parse(start) <= Date.parse(row.date) && Date.parse(end) >= Date.parse(row.date));
        this.lightSleepData = this.lightSleepData.filter((row: any) => Date.parse(start) <= Date.parse(row.date) && Date.parse(end) >= Date.parse(row.date));
        this.goodSleepData = this.goodSleepData.filter((row: any) => Date.parse(start) <= Date.parse(row.date) && Date.parse(end) >= Date.parse(row.date));
        this.theSleepData = this.theSleepData.filter((row: any) => Date.parse(start) <= Date.parse(row.date) && Date.parse(end) >= Date.parse(row.date));
        this.sleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        this.deepSleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        this.lightSleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        this.goodSleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        this.theSleepData.sort((a : any, b : any) => Number(new Date(a.date)) - Number(new Date(b.date)));
        this.theSleepGraph();
      }
    }, (err) => {
      console.log("err -> ", err);
    });
  }

  selectedDateChange() {
    var dataStart: string = this.selectedDateForm.value.start;
    var dataEnd: string = this.selectedDateForm.value.end;
    if (this.selectedDateForm.value.start != null && this.selectedDateForm.value.end != null) {
      var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
      var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);
      var newStart = new Date(dataStart);
      var newStartAddDay = newStart.setDate(newStart.getDate() - 1);
      dataStart_ = formatDate(newStartAddDay, 'yyyy-MM-dd', this.locale);
      var newEnd = new Date(dataEnd);
      var newEndAddDay = newEnd.setDate(newEnd.getDate() + 1);
      dataEnd_ = formatDate(newEndAddDay, 'yyyy-MM-dd', this.locale);
      this.getSleepData(dataStart_, dataEnd_);
    }
  }

  theSleepGraph() {
    this.changeDetectRef.detectChanges();
    am4core.useTheme(am4themes_animated);
    var chart = am4core.create(this.theSleepChart.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    chart.colors.step = 2;
    chart.legend = new am4charts.Legend()
    chart.legend.position = 'top'
    chart.legend.paddingBottom = 20
    chart.legend.labels.template.maxWidth = 95
    this.theSleepData = this.theSleepData.map((item: any) => ({
      ...item,
      date: this.datePipe.transform(item.date, "dd-MM-yyyy H:mm")
    }));
    chart.data = this.theSleepData;
    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = 'date'
    xAxis.renderer.cellStartLocation = 0.1
    xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.grid.template.location = 0;
    var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    function createSeries(value: string, name: string, text: string) {
      var series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = value
      series.dataFields.categoryX = 'date'
      series.name = name
      series.columns.template.tooltipText = `${value == "allSleep" ? "[bold]{date}:[/] " : ""}{${text}} [bold](${name})[/]`;
      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);
      var bullet = series.bullets.push(new am4charts.LabelBullet())
      bullet.interactionsEnabled = false
      bullet.dy = 30;
      bullet.label.text = `{${text}}`
      bullet.label.fill = am4core.color('#ffffff')
      return series;
    }
    function arrangeColumns() {
      var series: any = chart.series.getIndex(0);
      var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        var delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          var middle = chart.series.length / 2;
          var newIndex = 0;
          chart.series.each(function(series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            }
            else {
              series.dummyData = chart.series.indexOf(series);
            }
          })
          var visibleCount = newIndex;
          var newMiddle = visibleCount / 2;
          chart.series.each(function(series) {
            var trueIndex = chart.series.indexOf(series);
            var newIndex = series.dummyData;
            var dx = (newIndex - trueIndex + middle - newMiddle) * delta
            series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
            series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
          })
        }
      }
    }
    createSeries("allSleep", "All Sleep", "allSleepText");
    createSeries("deepSleep", "Deep Sleep", "deepSleepText");
    createSeries("lightSleep", "Light Sleep", "lightSleepText");
    createSeries("goodSleep", "REM", "goodSleepText");
    // let gradient = new am4core.LinearGradient();
    // gradient.addColor(am4core.color("#243b55"));
    // gradient.addColor(am4core.color("#141e30"));
    // gradient.rotation = 90;
    // chart.background.fill = gradient;
    // let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    // categoryAxis.dataFields.category = 'date';
    // categoryAxis.renderer.minGridDistance = 30;
    // categoryAxis.renderer.labels.template.rotation = 300;
    // categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    // categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");
    // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.min = 0;
    // valueAxis.max = 12;
    // valueAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    // valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");
    // if (this.theSleepData.length > 1) {
    //   var series = chart.series.push(new am4charts.LineSeries());
    //   series.dataFields.valueY = 'allSleep';
    //   series.dataFields.categoryX = 'date';
    //   series.strokeWidth = 5;
    //   series.minBulletDistance = 10;
    //   series.stroke = am4core.color('#ffffff');
    //   series.tooltipText = '[bold]{date}:[/] {allSleepText} [bold](All Sleep)[/]';
    //   var series_d = chart.series.push(new am4charts.LineSeries());
    //   series_d.dataFields.valueY = 'deepSleep';
    //   series_d.dataFields.categoryX = 'date';
    //   series_d.strokeWidth = 5;
    //   series_d.minBulletDistance = 10;
    //   series_d.stroke = am4core.color('#64dbfb');
    //   series_d.tooltipText = '[bold]{date}:[/] {deepSleepText} [bold](Deep Sleep)[/]';
    //   var series_l = chart.series.push(new am4charts.LineSeries());
    //   series_l.dataFields.valueY = 'lightSleep';
    //   series_l.dataFields.categoryX = 'date';
    //   series_l.strokeWidth = 5;
    //   series_l.minBulletDistance = 10;
    //   series_l.stroke = am4core.color('#FF9900');
    //   series_l.tooltipText = '[bold]{date}:[/] {lightSleepText} [bold](Light Sleep)[/]';
    //   var series_g = chart.series.push(new am4charts.LineSeries());
    //   series_g.dataFields.valueY = 'goodSleep';
    //   series_g.dataFields.categoryX = 'date';
    //   series_g.strokeWidth = 5;
    //   series_g.minBulletDistance = 10;
    //   series_g.stroke = am4core.color('#33FF00');
    //   series_g.tooltipText = '[bold]{date}:[/] {goodSleepText} [bold](REM Sleep)[/]';
    // } else {
    //   var series2 = chart.series.push(new am4charts.StepLineSeries());
    //   series2.dataFields.valueY = 'allSleep';
    //   series2.dataFields.categoryX = 'date';
    //   series2.strokeWidth = 5;
    //   series2.minBulletDistance = 10;
    //   series2.stroke = am4core.color('#ffffff');
    //   series2.tooltipText = '[bold]{date}:[/] {allSleepText} [bold](All Sleep)[/]';
    //   var series2_d = chart.series.push(new am4charts.StepLineSeries());
    //   series2_d.dataFields.valueY = 'deepSleep';
    //   series2_d.dataFields.categoryX = 'date';
    //   series2_d.strokeWidth = 5;
    //   series2_d.minBulletDistance = 10;
    //   series2_d.stroke = am4core.color('#64dbfb');
    //   series2_d.tooltipText = '[bold]{date}:[/] {deepSleepText} [bold](Deep Sleep)[/]';
    //   var series2_l = chart.series.push(new am4charts.StepLineSeries());
    //   series2_l.dataFields.valueY = 'lightSleep';
    //   series2_l.dataFields.categoryX = 'date';
    //   series2_l.strokeWidth = 5;
    //   series2_l.minBulletDistance = 10;
    //   series2_l.stroke = am4core.color('#FF9900');
    //   series2_l.tooltipText = '[bold]{date}:[/] {lightSleepText} [bold](Light Sleep)[/]';
    //   var series2_g = chart.series.push(new am4charts.StepLineSeries());
    //   series2_g.dataFields.valueY = 'goodSleep';
    //   series2_g.dataFields.categoryX = 'date';
    //   series2_g.strokeWidth = 5;
    //   series2_g.minBulletDistance = 10;
    //   series2_g.stroke = am4core.color('#33FF00');
    //   series2_g.tooltipText = '[bold]{date}:[/] {goodSleepText} [bold](REM Sleep)[/]';
    // }
    // var axisRangetop = valueAxis.axisRanges.create();
    // axisRangetop.value = 40;
    // axisRangetop.grid.strokeOpacity = 1;
    // axisRangetop.grid.stroke = am4core.color('#f3260e'); //00ba00
    // axisRangetop.grid.strokeWidth = 2;
    // var danger = valueAxis.axisRanges.create();
    // danger.value = 0;
    // danger.endValue = 7;
    // danger.axisFill.fill = am4core.color('#f3260e');
    // danger.axisFill.fillOpacity = 0.2;
    // danger.grid.strokeOpacity = 0;
    // var normal = valueAxis.axisRanges.create();
    // normal.value = 7;
    // normal.endValue = 9;
    // normal.axisFill.fill = am4core.color('#00ba00');
    // normal.axisFill.fillOpacity = 0.2;
    // normal.grid.strokeOpacity = 0;
    // var warning = valueAxis.axisRanges.create();
    // warning.value = 9;
    // warning.endValue = 12;
    // warning.axisFill.fill = am4core.color('#f0ad4e');
    // warning.axisFill.fillOpacity = 0.2;
    // warning.grid.strokeOpacity = 0;
    // chart.cursor = new am4charts.XYCursor();
    // chart.cursor.xAxis = categoryAxis;
  }
}
