import { Injectable } from '@angular/core';
import { catchError, map, retry, timeout } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { UserModule } from '../models/user/user.module';
import { AdminModule, checkDeviceLogoutModule } from '../models/admin/admin.module';
import { JobOperatorModule } from '../models/jobOperator/jobOperator.module';
import { JobTaskOperatorModule } from '../models/jobOperator/jobTaskOperator.module';
import { OperatorModule } from '../models/operators/operator.module';
import { GlobalConstants } from 'src/app/common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  // Real Server
  REST_API: string = GlobalConstants.imageUrl1;
  REST_API_2021: string = GlobalConstants.imageUrl2;
  REST_API_2077: string = GlobalConstants.imageUrl3;

  // For Test
  // REST_API: string = 'https://uat-portal-api.medilifewellness.com';
  // REST_API_2021: string = 'https://uat-api.medilifewellness.com';
  // REST_API_2077: string = 'https://uat-tele.medilifewellness.com';

  // REST_API: string = 'http://api.achatsocial.com:8032/';
  // REST_API: string = 'http://localhost:49274';
  // REST_API: string = 'https://localhost:44316'; //local
  // REST_API: string = 'http://183.88.230.105:1112';
  // REST_API_2021: string = 'http://192.168.1.32:2021';

  // REST_API_2077: string = 'https://localhost:44398';//local

  // REST_API_2021: string = 'http://183.88.230.105:2021';
  // REST_API_2021: string = 'http://94.74.112.40:2021';

  ipServer: string = "https://localhost:44378";

  //Http header
  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })

  };
  u_Token?: string;

  constructor(private httpClient: HttpClient) {
    this.u_Token = localStorage.getItem("currentUser")?.toString();
  }

  Login_(data: AdminModule) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/Login`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  CheckDeviceLogoutModule_(data: checkDeviceLogoutModule) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/checkDeviceLogout`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  LoginAuthLog_(post: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/LoginAuthLog`;
      this.httpClient.post(API_URL, post, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  CheckToken(auth_token: any): Observable<any> {
    let API_URL = `${this.REST_API}/api/admin/Info`;
    var data = this.httpClient.get(API_URL, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${auth_token}` }) })
    // console.log("data lll l ",data );

    return data
      .pipe(timeout(2000), map((res: any) => {
        // console.log("555555555555+ ",res);

        return res || {}
      }),
        catchError(e => {
          return of(null)
        })
      )
  }

  AddUser(data: UserModule): Observable<any> {
    let API_URL = `${this.REST_API}/api/smartwatch/addUsers`;
    // var headers2 = new Headers();
    //   headers2.append('Content-Type', 'application/json');
    // this.httpClient
    // .post(API_URL, JSON.stringify(data),this.httpOptions)
    // .subscribe(data => {
    //   console.log(data);
    // });
    return this.httpClient.post(API_URL, { data: JSON.stringify(data) }, {
      headers: this.httpHeaders,
      responseType: "text"
    })
      .pipe(
        catchError(this.handleError)
      )
  }

  AddAdmin(data: AdminModule): Observable<any> {
    let API_URL = `${this.REST_API}/api/admin/dashboard/addAdminProvince`;
    return this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
      .pipe(
        catchError(this.handleError)
      )
  }

  GetAdmin_(adminId: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/ProvinceDistrictSubDistrict/dashboard/GetdataAdmin?idAdmin=${adminId}`;
      this.httpClient.get(API_URL, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${this.u_Token}` }), responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  EditAdmin_(data: AdminModule) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/dashboard/UpdateAdmin`;
      this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${this.u_Token}` }), responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetAdminProvincesDialog_() {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/dashboard/GetAdminProvince`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetListOperatorInAdmin_(idAdmin: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/dashboard/GetListOperatorInAdmin?idAdmin=${idAdmin}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // ***************************************************User and device *******************************************************

  GetSensors(imeiID: string) {
    let API_URL = `${this.REST_API_2021}/api/getlast/alllog?imeicode=${imeiID}`;
    return this.httpClient.get(API_URL, { headers: this.httpHeaders })
      .pipe(map((res: any) => {
        return res || {}
      }),
        catchError(this.handleError)
      )
  }

  GetGetUserInfomation_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API_2021}/api/sosapi/getUserInfomation`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetSensors_(imeiID: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API_2021}/api/smartwatch_cn_api/getCN_LogMainLog?imeicode=${imeiID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetSensorsOld_(imeiID: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API_2021}/api/getlast/alllog?imeicode=${imeiID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetUsers_(abbreviation: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/GetUsers?abbreviation=${abbreviation}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetUsersAll_() {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/GetUsersAll`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateTypeAndPassUser_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API_2077}/api/telemedicine/user/UpdateTypeAndPassUser`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateUserType_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API_2077}/api/telemedicine/user/UpdateUserType`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  DeleteUserTelemed_(idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API_2077}/api/telemedicine/user/DeleteUserTelemed?userId=${idUser}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetUsersAndDevice_(abbreviation: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/GetUsersAndDevice?abbreviation=${abbreviation}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetUsersAndDeviceV2_(abbreviation: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/GetUsersAndDeviceV2?abbreviation=${abbreviation}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  SearchUserPhone(data: string, type: string, monotorStatus: boolean): Observable<any> {
    let API_URL = `${this.REST_API}/api/Users/SearchUserPhone?data=${data}&type=${type}&monotorStatus=${monotorStatus}`;
    return this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
      .pipe(map((res: any) => {
        return res || {}
      }),
        catchError(this.handleError)
      )
  }

  GetDevices_() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Device/GetDevices`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  SearchDevice(data: string): Observable<any> {
    let API_URL = `${this.REST_API}/api/Device/SearchDevice?data=${data}`;
    return this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
      .pipe(map((res: any) => {
        return res || {}
      }),
        catchError(this.handleError)
      )
  }

  GetDeviceBySerial_(idSerial: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Device/GetDeviceBySerial?idSerial=${idSerial}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetDeviceWatch_(id: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Device/GetDevice?deviceID=${id}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetPhoneConnectWatch_(id: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Device/GetPhoneConnectWatch?idUser=${id}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetWatchConnectPhone_(id: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/GetWatchConnectPhone?idUser=${id}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetUserPhone_(id: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/GetUser?IdUser=${id}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetUserReport_(TypeUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/GetUserReport?TypeUser=${TypeUser}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetUsersByIdAdmin_(idOperatorAdmin: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/GetUsersByIdAdmin?idOperatorAdmin=${idOperatorAdmin}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // GetDevice_() {
  //   return new Promise((resolve, reject) => {
  //     let API_URL = `${this.REST_API}/api/Users/GetDevices`;
  //     this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
  //       .subscribe(data => {
  //         resolve(data);
  //       },
  //         error => {
  //           reject(error);
  //         });
  //   });
  // }

  GetLogSos_(objid: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API_2021}/api/sosapi/getLogSosObjID?objid=${objid}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetDeviceUserPhoneAccountByDeviceUserId_(deviceUserId: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetDeviceUserPhoneAccountByDeviceUserId?deviceUserId=${deviceUserId}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetLogHeartRateList_(idEmi: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API_2021}/api/heartRate/getLogHeartRateList?imeicode=${idEmi}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetLogHeartDateFilterList_(idEmi: any, dateStart: string, dateEnd: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API_2021}/api/heartRate/getLogHeartDateFilterList?imeicode=${idEmi}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetLogLocationList_(idEmi: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API_2021}/api/location/getLogLocationList?imeicode=${idEmi}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetLogLocationDateFilterList_(idEmi: any, dateStart: string, dateEnd: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API_2021}/api/location/getLogLocationDateFilterList?imeicode=${idEmi}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateNoteCommentUser_(idUser: any, note: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/UpdateNoteCommentUser?idUser=${idUser}&note=${note}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // GetDataListUserFilter(DateStart: string, DateEnd: string, BatteryStart: string, BatteryEnd: string, PlaceNameStart: string, PlaceNameEnd: string, FilterDate: boolean, FilterBattery: boolean, FilterPlaceName: boolean, CheckData: boolean): Observable<any> {
  //   let API_URL = `${this.REST_API}/api/Users/GetDataListUsers?DateStart1=${DateStart}&DateEnd1=${DateEnd}&BatteryStart1=${BatteryStart}&BatteryEnd1=${BatteryEnd}&PlaceNameStart1=${PlaceNameStart}&PlaceNameEnd1=${PlaceNameEnd}&FilterDate1=${FilterDate}&FilterBattery1=${FilterBattery}&FilterPlaceName1=${FilterPlaceName}&CheckData=${CheckData}`;
  //   // let API_URL = `http://localhost:49274/api/smartwatch/GetDataUserTable?IdUser=058fba06-6800-457f-93f7-3dcc5ce03e62&DateStart=2/1/2021&DateEnd=4/1/2021&checkData=false`;
  //   return this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
  //     .pipe(map((res: any) => {
  //       return res || {}
  //     }),
  //       catchError(this.handleError)
  //     )
  // }

  GetListUserCovid19_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/GetListUserCovid19`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // ***************************************************End User and device *******************************************************

  GetAdminProvince_(id: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/dashboard/GetDataAdminById?idAdmin=${id}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetAdminDistrictAndSubDistrict_(AdminRole: string, AdminProvinceID_DistrictID: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/dashboard/GetAdminDistrictAndSubDistrict?AdminRole=${AdminRole}&Admin_ProvinceID_DistrictID=${AdminProvinceID_DistrictID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetAdminHeaderDistricts_(AdminParrent: string, AdminDistrictID: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/dashboard/GetAdminDistrict?AdminParrent=${AdminParrent}&AdminDistrictID=${AdminDistrictID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetAdminSubDistricts_(ProvinceID: string, DistrictID: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/dashboard/GetAdminSubDistricts?ProvinceID=${ProvinceID}&DistrictID=${DistrictID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetAdminDistricts_(ProvinceID: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/dashboard/GetAdminDistricts?ProvinceID=${ProvinceID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetAdminOperatorSubDistricts_(AdminParrent: string, AdminSubDistrictID: string, AdminID: string, checkId: boolean) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/dashboard/GetAdminOperatorSubDistrict?AdminParrent=${AdminParrent}&AdminSubDistrictID=${AdminSubDistrictID}&AdminID=${AdminID}&checkId=${checkId}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetProvinceSelect_() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/ProvinceDistrictSubDistrict/dashboard/GetProvinceDropdownSelect`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetDistrict_(idNumber: number) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/ProvinceDistrictSubDistrict/dashboard/GetDistrictDropdownSelect?idDistrict=${idNumber}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetSubdistrict_(idNumber: number) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/ProvinceDistrictSubDistrict/dashboard/GetSubDistrictDropdownSelect?idSubDistrict=${idNumber}`
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetProvinceByName_(ProvinceId: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/ProvinceDistrictSubDistrict/dashboard/GetProvinceById?ProvinceId=${ProvinceId}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetDistrictByName_(DistrictId: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/ProvinceDistrictSubDistrict/dashboard/GetDistrictById?DistrictId=${DistrictId}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetSubdistrictByName_(SubDistrictId: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/ProvinceDistrictSubDistrict/dashboard/GetSubDistrictById?SubDistrictId=${SubDistrictId}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  removeAdmins_(idAdmin: string, idParent: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/dashboard/RemoveAdmin/${idAdmin}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // ***************************************************************** operator ******************************************

  EditOperator_(data: OperatorModule) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/operator/dashboard/UpdateOperator`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateStatusOperator_(idOperator: string, status: boolean) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Operator/dashboard/UpdateStatusOnline?idOperatorAdmin=${idOperator}&status=${status}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  AddOperator_(data: OperatorModule) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Operator/dashboard/addOperator`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  removeOperator_(id: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Operator/dashboard/RemoveOperator?idOperator=${id}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  SetStatusOperator_(OperatorId: string, Status: boolean) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/admin/SetStatusOperator?admin_id=${OperatorId}&status=${Status}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UploadIMGProfile_(data: FormData) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/operator/dashboard/UploadImgOperator`;
      // this.httpClient.post(API_URL, data, { responseType: "text" })
      this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.u_Token}` }), responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetAdminGetOperators_(subDistrictID: string, adminID: string, checkId: boolean) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Operator/dashboard/GetOperators?adminSubDistrictID=${subDistrictID}&adminID=${adminID}&checkId=${checkId}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetAdminGetOperator_(idOperator: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Operator/dashboard/GetOperator?idOperator=${idOperator}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetIdOperatorAdminByIdOperator_(Id: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Operator/dashboard/GetIdOperatorAdminByIdOperator?Id=${Id}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetIdOperatorByIdOperatorAdmin_(Id: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Operator/dashboard/GetIdOperatorByIdOperatorAdmin?Id=${Id}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetIdOperatorByAbbreviation_(abbreviation: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Operator/dashboard/GetIdOperatorByAbbreviation?abbreviation=${abbreviation}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // ***************************************************************** end operator ******************************************

  // **********************************************Alert SOS*********************************************** //

  loadSOS_(idOperator: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobOperators?idOperator=${idOperator}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobByOperators_(idOperatorAdmin: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobByOperators?idOperator=${idOperatorAdmin}`;
      this.httpClient.get(API_URL, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${this.u_Token}` }), responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobByOperatorsCannotSelectToday_(idOperatorAdmin: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobByOperatorsCannotSelectToday?idOperator=${idOperatorAdmin}`;
      this.httpClient.get(API_URL, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${this.u_Token}` }), responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobByOperatorsToday_(idOperatorAdmin: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobByOperatorsToday?idOperator=${idOperatorAdmin}`;
      this.httpClient.get(API_URL, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${this.u_Token}` }), responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobOperatorsAll_(adminRole: string, abbreviation: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobOperatorsAll?adminRole=${adminRole}&abbreviation=${abbreviation}`;
      this.httpClient.get(API_URL, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${this.u_Token}` }), responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobOperatorsCaseTaker_(status: string, abbreviation: string, filterDate: boolean, dateStart: string, dateEnd: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobOperatorsCaseTaker?status=${status}&abbreviation=${abbreviation}&filterDate=${filterDate}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobToday_(adminRole: string, abbreviation: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobToday?adminRole=${adminRole}&abbreviation=${abbreviation}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobAllCannotSelectToday_(adminRole: string, abbreviation: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobAllCannotSelectToday?adminRole=${adminRole}&abbreviation=${abbreviation}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobTaskOperator_(idJob: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobTaskOperator?idJob=${idJob}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobOperatorByJobId_(idJob: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobOperatorByJobId?idJob=${idJob}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobCommentOperator_(idJob: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobCommentOperator?idJob=${idJob}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobEndHistoryOperator_(idOperator: string, statusSOS: string, limit: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobEndHistoryOperator?idOperator=${idOperator}&status=${statusSOS}&limit=${limit}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobTodayOperator_(idOperator: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobTodayOperator?idOperator=${idOperator}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  AddCommentJobOperator_(data: JobTaskOperatorModule) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/AddJobTaskOperator`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  updateStatusSosAlert_(data: JobOperatorModule) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/UpdateStatusJobOperator`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  updateCaseMobile_(data: JobOperatorModule) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/UpdateStatusJobOperatorSocketMobile`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateAddressJob(idJob: number, address: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/UpdateAddressJob?idJob=${idJob}&address=${address}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobOperatorStart_(idOperator: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobOperatorStart?idOperator=${idOperator}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateCountJobOperator_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/UpdateCountJobOperator`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetListJobOperatorsAll_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetListJobOperatorsAll`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetListJobOperatorsAllV2_(data: any) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetListJobOperatorsAllV2`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetJobByIdUser_(idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetJobByIdUser?idUser=${idUser}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // **********************************************END-SOS******************************************* //

  // **********************************************START-SOS***************************************** //

  GetListHistoryBody_(idUser: string) {
    return new Promise((resolve, reject) => {
      // let API_URL = `${this.REST_API}/api/HistoryBody/GetListHistoryBody?idUser=${idUser}`;
      let API_URL = `${this.REST_API_2021}/HistoryBody/GetTbCovidSymptomById?userid=${idUser}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetListDrugAllergy_(idUser: string) {
    return new Promise((resolve, reject) => {
      // let API_URL = `${this.REST_API}/api/HistoryBody/GetListDrugAllergy?idUser=${idUser}`;
      let API_URL = `${this.REST_API_2021}/HistoryBody/GetTbPersonalHealthById?userid=${idUser}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetPersonalHealth_(idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/Users/GetPersonalHealth?idUser=${idUser}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // **********************************************END-SOS******************************************* //

  // **********************************************START-ADD-ADMIN-ROLE******************************************* //

  AddRoleAdmin_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/TypeMonitor/dashboard/addRoleAdmin`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateAdminRole_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/TypeMonitor/dashboard/updateAdminRole`;
      this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  RemoveRoleAdmin_(idRole: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/TypeMonitor/dashboard/RemoveRoleAdmin?idRole=${idRole}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetAdminRole_() {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/TypeMonitor/dashboard/GetAdminRole`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // **********************************************END-ADD-ADMIN-ROLE******************************************* //

  // **********************************************START-CovidSymptomV2******************************************* //

  GetCovidSymptomV2_(idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/CovidSymptomV2/dashboard/GetCovidSymptomV2?idUser=${idUser}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetCovidSymptomV4_(idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/CovidSymptomV2/dashboard/GetCovidSymptomV4?idUser=${idUser}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateVitalSign_(data_: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/CovidSymptomV2/dashboard/UpdateVitalSign`;
      this.httpClient.post(API_URL, data_, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  ViewReferUsercoviViewReferUsercovid19_p_(data_: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/CovidSymptomV2/ViewReferUsercovid19`;
      this.httpClient.post(API_URL, data_, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // **********************************************END-CovidSymptomV2******************************************* //

  // **********************************************START-DoctorMall******************************************* //

  GetDataDoctorMallList_(status: string, covidStatus: string, countLists: string, operatorAdminID: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/GetDataDoctorMallList?status=${status}&covidStatus=${covidStatus}&countList=${countLists}&operatorAdminID=${operatorAdminID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetDataDoctorPatient_(idPatient: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/GetDataDoctorPatient?idPatient=${idPatient}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetDataDoctorPatientAndDoctorTask_(idPatient: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/GetDataDoctorPatientAndDoctorTask?idPatient=${idPatient}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetPatientCovidForDoctor_(idPatient: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/GetPatientCovidForDoctor?idPatient=${idPatient}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetdataProfileUserCovidAll_(idPatient: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/GetdataProfileUserCovidAll?idPatient=${idPatient}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetDrugUserCovid19ByUser_(userID: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/GetDrugUserCovid19ByUser?userID=${userID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetLogCovid19Alert_(idOperatorID: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/GetLogCovid19Alert?idOperatorID=${idOperatorID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetdataReferHistory_(idPatient: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/GetdataReferHistory?idPatient=${idPatient}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateDataDoctorMallUser_(idDoctorMall: string, doctorMallStatus: string, idOperatorAdmin: string, userID: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/UpdateDataDoctorMallUser?idDoctorMall=${idDoctorMall}&doctorMallStatus=${doctorMallStatus}&idDoctor=${idOperatorAdmin}&userID=${userID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateDocCovidStatusAndFavorite_(idPatient: string, docCovidStatus: string, favorite: boolean) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/UpdateDocCovidStatusAndFavorite?idPatient=${idPatient}&docCovidStatus=${docCovidStatus}&favorite=${favorite}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  UpdateDocCovidStatusSeeUSer_(daID: string, status: boolean) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/UpdateDocCovidStatusSeeUSer?daID=${daID}&status=${status}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  AddCommentDoctorPatient_(data_: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/AddCommentDoctorPatient`;
      this.httpClient.post(API_URL, data_, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  AddReferPatientCovid19_(data_: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/AddReferPatientCovid19`;
      this.httpClient.post(API_URL, data_, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  reciveCovidSym_(data_: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.socket}/reciveCovidSym`;
      this.httpClient.post(API_URL, data_, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // **********************************************END-DoctorMall******************************************* //

  // **********************************************START-MedicalPrescribtion******************************************* //
  AddMedicalPrescribtion_(data_: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/MedicalPrescribtion/dashboard/AddMedicalPrescribtion`;
      this.httpClient.post(API_URL, data_, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  AddMedicalPrescribtionTH_(data_: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/MedicalPrescribtion/dashboard/AddMedicalPrescribtionTH`;
      this.httpClient.post(API_URL, data_, { headers: this.httpHeaders, responseType: "text" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // **********************************************END-MedicalPrescribtion******************************************* //

  GetAdminCovAlert(idOperatorID: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/getCovAdminAlert?idOperatorID=${idOperatorID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetDoctorPatientTask(DptID: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/getDoctorPatientTaskByID?DptID=${DptID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetMedicalPrescribtionByID(MpID: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/getMedPresByID?MpID=${MpID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetMedicalMedPresTHByID(MpID: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/getMedPresTHByID?MpID=${MpID}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  changeDoctorPatientStatus(alertID: any, status: boolean) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/changeDoctorPatientStatus?alertID=${alertID}&status=${status}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  changeMedPresStatus(alertID: any, status: boolean, dateChange: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/changeMedPresStatus?alertID=${alertID}&status=${status}&dateChange=${dateChange}`;
      // let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/changeMedPresStatus`;

      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  getLogMainLogList(imeicode: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${this.REST_API_2021}/api/smartwatch_cn_api/getCN_LogMainLogList?imeicode=${imeicode}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // ********************************************** Get Dispense Medicine History ******************************************* //
  GetDataCovidDispense_(userID: string, countLists: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/getMedPresListByUser?userID=${userID}&countList=${countLists}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  GetGetMedPresTHListByUser_(userID: string, countLists: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API}/api/DoctorMall/dashboard/getMedPresTHListByUser?userID=${userID}&countList=${countLists}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }
  // ********************************************** Get Dispense Medicine History END******************************************* //

  // ********************************************** Get smartwatch_cn_api START ******************************************* //

  GetCNLogSpo2List_(imeiCode: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.REST_API_2021}/api/smartwatch_cn_api/getCN_LogSpo2List?imeicode=${imeiCode}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
          error => {
            reject(error);
          });
    });
  }

  // ********************************************** Get smartwatch_cn_api END ******************************************* //

  //error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Hendle client error
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;;
    }
    // console.log("aaaaaaaaaaa ",errorMessage);
    return throwError(errorMessage);

  }

    // ********************************************** Get spo2 blood, pressure and sleep ******************************************* //

    GetPressureList_(imeiCode: any) {
      return new Promise((resolve, reject) => {
        let API_URL = `${this.REST_API_2021}/api/smartwatch_cn_api/getCN_LogBloodPre?imeicode=${imeiCode}`;
        this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
        error => {
          reject(error);
        });
      });
    }

    GetSpo2List_(imeiCode: any) {
      return new Promise((resolve, reject) => {
        let API_URL = `${this.REST_API_2021}/api/smartwatch_cn_api/getCN_LogSpo2?imeicode=${imeiCode}`;
        this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
        error => {
          reject(error);
        });
      });
    }

    GetSleepList_(imeiCode: any) {
      return new Promise<any>((resolve, reject) => {
        let API_URL = `${this.REST_API_2021}/api/smartwatch_cn_api/getCN_LogSleepList?imeicode=${imeiCode}`;
        this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(data => {
          resolve(data);
        },
        error => {
          reject(error);
        });
      });
    }

    // ********************************************** Request PDF ******************************************* //

      AddRequestPDF_(jobId: any) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API}/api/JobOperator/dashboard/AddRequestPDF?jobId=${jobId}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      GetRequestPDF_(adminId: any) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API}/api/JobOperator/dashboard/GetRequestPDF?adminId=${adminId}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      UpdateRequestPDF_(data: any) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API}/api/JobOperator/dashboard/UpdateRequestPDF`;
          this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      CheckApprovedPdfByOpertor_(jobId: any) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API}/api/JobOperator/dashboard/CheckApprovedPdfByOpertor?jobId=${jobId}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      // ********************************************** Add User Rux ******************************************* //

      AddUserRux_(data: any) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2077}/api/telemedicine/login/InsertRegisterTelemedicine`;
          this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      // ********************************************** Admin Filter ******************************************* //

      AddAdminFilter_(data: any) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API}/api/admin/dashboard/addAdminProvince`;
          this.httpClient.post(API_URL, data, { headers: this.httpHeaders, responseType: "text" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      GetTickerAlertList_() {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/smartwatch_ticker/getTickerAlertList`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      SendTickerToSos_(tk_runid: number, opid: string, acceptstatus: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/smartwatch_ticker/sendTickerToSos?tk_runid=${tk_runid}&opid=${opid}&acceptstatus=${acceptstatus}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      cancelTickerCaseByListID_(post: any) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/smartwatch_ticker/cancelTickerCaseByListID`;
          this.httpClient.post(API_URL, post, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      GetTickerLogList_() {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/smartwatch_ticker/getTickerLogListV2`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      GetTickerLogListByImei_(imei: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/smartwatch_ticker/getTickerLogListByImei?imei=${imei}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      getSmartwatchStatusList_() {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/smartwatch_ticker/getSmartwatchStatusList`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      getSmartwatchStatusInfoByImei_(imei: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/smartwatch_ticker/getSmartwatchStatusInfoByImei?imei=${imei}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      getWhitelistDevice_() {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API}/api/WhiteListIP/getWhitelistDevice`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      addWhitelistDevice_(post: any) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API}/api/WhiteListIP/addWhitelistDevice`;
          this.httpClient.post(API_URL, post, { headers: this.httpHeaders, responseType: "text" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      editWhitelistDevice_(post: any) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API}/api/WhiteListIP/editWhitelistDevice`;
          this.httpClient.post(API_URL, post, { headers: this.httpHeaders, responseType: "text" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      removeWhitelistDevice_(id: number) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API}/api/WhiteListIP/removeWhitelistDevice?id=${id}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      getAllSmartwatchList_() {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/smartwatch_ticker/getAllSmartwatchList`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      forceOpOfflineByID_(adminId: string, operatorId: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/sosapi/forceOpOfflineByID?adminID=${adminId}&opID=${operatorId}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      getLoginAttempList_() {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/Account/getLoginAttempList`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      removeLoginAttempByID_(AttemptId: number) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/Account/removeLoginAttempByID?id=${AttemptId}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "text" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      GetPacKageUserNoAuthV1_(UserId: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2077}/api/telemedicine/package/GetPacKageUserNoAuthV1?userId=${UserId}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      GetUserPackageActivateByserID_(UserId: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2077}/api/Audit/GetUserPackageActivateByserID?userId=${UserId}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      getCN_RespiraList_(imei: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/smartwatch_cn_api/getCN_RespiraList?imeicode=${imei}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      getReportSmartWatchLostDataDailyList_(StartDate: string, EndDate: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/smartwatch_cn_api/getReportSmartWatchLostDataDailyList?startDay=${StartDate}&endDay=${EndDate}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      getOperatorIdList_(AdminID: string, AdminType: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/Account/getOperatorIdList?adminId=${AdminID}&opType=${AdminType}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      /// Watch Management

      getWatchManagementList_() {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/SmartWatch_Manage_API/getSmartWatchList`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      getWatchManagementHistory_() {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/SmartWatch_Manage_API/getPairDeviceHistory`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      getWatchManagementListByID_(SerialID: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/SmartWatch_Manage_API/getSmartWatchInfoBySerial?SwSerial=${SerialID}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      removePairedWatch_(DeviceID: string) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/SmartWatch_Manage_API/removePairSmartWatchByDeviceId?deviceID=${DeviceID}`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      trackWatch(TrackingList: any) {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/SmartWatch_Report_API/setSubmitStatusSmartwatch`;
          this.httpClient.post(API_URL, TrackingList, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }

      updateBluetoothDeviceData() {
        return new Promise<any>((resolve, reject) => {
          let API_URL = `${this.REST_API_2021}/api/SmartWatch_Report_API/updateBluetoothDeviceData`;
          this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" })
            .subscribe(data => {
              resolve(data);
            },
              error => {
                reject(error);
              });
        });
      }
}
