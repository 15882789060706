import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { SharedServiceService } from 'src/app/common/shared-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-bloodpressure',
  templateUrl: './bloodpressure.component.html',
  styleUrls: ['./bloodpressure.component.scss'],
})
export class BloodPressureComponent implements OnInit {
  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  ipServer: string = GlobalConstants.imageUrl2;
  // ipServer: string = "https://localhost:44378";

  pageGetDatatype: string = 'pressure_SBP,pressure_DBP';
  tickerDataToTableList: any[] = [];

  imeiList: any[] = [];

  dataChartBloodPre: any[] = [];
  dataCalculateBloodPre: any[] = [];
  dataChart24List: any[] = [];
  countChartData = 1;

  dataChart24List_Day1: any[] = [];
  dataChart24List_new: any[] = [];

  dataCalculateBloodPre_Day1: any[] = [];

  filterEmtyData: any[] = [];
  datetimeShowList: any[] = [];

  minBloodPreValue: any = 0;
  maxBloodPreValue: any = 0;
  averBloodPreValue: any = 0;

  minBloodPreValue_2: any = 0;
  maxBloodPreValue_2: any = 0;
  averBloodPreValue_2: any = 0;

  countBloodPreLow20: any = 0;

  countelevated: any = 0;
  countvalhighstate1: any = 0;
  countvalhighstate2: any = 0;
  countvalhighcrisis: any = 0;
  countvalNormal: any = 0;

  rangeDateStartAndEnd = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date()),
  });

  gimeiCode: any = '';

  @ViewChild('chartBloodPre', { static: false }) chartBloodPre!: ElementRef;

  @ViewChild('chartBloodPreNew', { static: false })
  chartBloodPreNew!: ElementRef;
  @ViewChild('chartBloodPreAver24Line', { static: false })
  chartBloodPreAver24Line!: ElementRef;
  @ViewChild('chartBloodPreAver7day', { static: false })
  chartBloodPreAver7day!: ElementRef;
  @ViewChild('chartBloodPreValPercen', { static: false })
  chartBloodPreValPercen!: ElementRef;

  imei: string = "";
  value: string = "";
  setNow: any = new Date();

  constructor(
    private httpClient: HttpClient,
    private sharedServiceService: SharedServiceService,
    private changeDetectRef:ChangeDetectorRef,
    @Inject(LOCALE_ID) private locale: string,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.imei = this.route.snapshot.params["imei"];
    this.value = this.route.snapshot.params["value"];
  }

  ngOnInit(): void {
    // this.loadIMEIListData();
    this.sharedServiceService.deviceIDValue.subscribe((swimei) => {
      // console.log("message ID ", message);
      var now = new Date();

      var nowStart = formatDate(now, 'yyyy-MM-dd', this.locale);
      var nowEnd = formatDate(now.setDate(now.getDate() + 1), 'yyyy-MM-dd', this.locale);

      if (!this.value) {
        this.loadDataChart(swimei ? swimei : this.imei, nowStart, nowEnd);
      } else {
        var sentStartDate: any = this.datePipe.transform(this.value, "yyyy-MM-dd")?.toString();
        var addOneDate = new Date(this.value);
        addOneDate.setDate(addOneDate.getDate() + 1);
        var sentEndDate: any = this.datePipe.transform(addOneDate, "yyyy-MM-dd")?.toString();
        this.loadDataChart(swimei ? swimei : this.imei, sentStartDate, sentEndDate);
      }
      this.rangeDateStartAndEnd.setValue({
        start: sentStartDate,
        end: sentStartDate
      });
      let dateNow = this.datePipe.transform(new Date(), "yyyy-MM-dd");
      setTimeout(async () => {
        await this.onChangeDateTimeEvent(dateNow, true);
      }, 1500);
    });
  }

  onChangeDate() {
    var dataStart: string = '';
    var dataEnd: string = '';

    dataStart = this.rangeDateStartAndEnd.value.start;
    dataEnd = this.rangeDateStartAndEnd.value.end;

    if (this.rangeDateStartAndEnd.value.start != null &&this.rangeDateStartAndEnd.value.end != null) {
      var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
      var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);

      var newEnd = new Date(dataEnd);
      var newEndAddDay = newEnd.setDate(newEnd.getDate() + 1);
      dataEnd_ = formatDate(newEndAddDay, 'yyyy-MM-dd', this.locale);
      // console.log("dataStart_ ", dataStart_, " | dataEnd_ ", dataEnd_);

      this.loadDataChart(this.gimeiCode, dataStart_, dataEnd_);
    }
  }

  loadIMEIListFormAPI() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_BloodPressure_CollectionList`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async loadIMEIListData() {
    await this.loadIMEIListFormAPI().then(
      (res: any) => {
        console.log(res);
        this.imeiList = res;
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  loadChartFormAPI(imeiCode: string, dateStart: string, dateEnd: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_LogBloodPreDateFilterList?imeicode=${imeiCode}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
      // console.log("loadChartFormAPI URL",API_URL);

      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async loadDataChart(imeiCode: string, dateStart: string, dateEnd: string) {
    await this.loadChartFormAPI(imeiCode, dateStart, dateEnd).then(
      (resq: any) => {
        console.log('resq 1 -------------> ', resq);
        this.countChartData = resq.length;
        var res = [];

        this.filterEmtyData = [];
        this.datetimeShowList = [];

        this.gimeiCode = imeiCode;
        this.dataCalculateBloodPre = [];
        this.dataChartBloodPre = [];
        this.dataChart24List = [];

        this.minBloodPreValue = 0;
        this.maxBloodPreValue = 0;
        this.averBloodPreValue = 0;

        this.minBloodPreValue_2 = 0;
        this.maxBloodPreValue_2 = 0;
        this.averBloodPreValue_2 = 0;

        this.countelevated = 0;
        this.countvalhighstate1 = 0;
        this.countvalhighstate2 = 0;
        this.countvalhighcrisis = 0;
        this.countvalNormal = 0;

        this.countBloodPreLow20 = 0;

        for (var i = 0; i < resq.length; i++) {
          if (resq[i].blood_pressure_SBP >= 10) {
            res.push({
              id: resq[i].id,
              imeicode: resq[i].imeicode,
              blood_pressure_SBP: resq[i].blood_pressure_SBP,
              blood_pressure_DBP: resq[i].blood_pressure_DBP,
              time_log: resq[i].time_log,
            });
          }
        }

        console.log('res filter o and null -------------> ', res);

        // res.sort(function (a: any, b: any) {
        //   return +new Date(a.time_log) - +new Date(b.time_log);
        // });

        for (let i = 0; i < res.length; i++) {
          this.dataChartBloodPre.push({
            // date: formatDate(res[i].time_log, 'yyyy/MM/dd hh:mm', 'en-US'),
        date: res[i].time_log.split("T")[0]+" "+res[i].time_log.split("T")[1].substring(0,5),

            dateformat: new Date(
              formatDate(res[i].time_log, 'yyyy/MM/dd', 'en-US')
            ).toLocaleDateString('en-US'),
            value1: Number(res[i].blood_pressure_SBP),
            value2: Number(res[i].blood_pressure_DBP),
          });

          this.dataCalculateBloodPre.push({
            date: new Date(res[i].time_log),
            time_h: new Date(
              formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
            ).getHours(),
            time_day: new Date(
              formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
            ).getDay(),
            value1: Number(res[i].blood_pressure_SBP),
            value2: Number(res[i].blood_pressure_DBP),
          });
        }
        this.dataCalculateBloodPre = this.dataCalculateBloodPre.map((item: any) => ({
          ...item,
          date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
          time_h: item.time_h - 7
        }));

        this.dataChartBloodPre.sort(function (a: any, b: any) {
          return + new Date(a.date) - + new Date(b.date);
        });

        for (var i = 0; i < this.dataChartBloodPre.length; i++) {
          if (
            this.dataChartBloodPre[i].value1 != 0 &&
            this.dataChartBloodPre[i].value1 != NaN
          ) {
            this.filterEmtyData.push({
              dateformat: this.dataChartBloodPre[i].dateformat,
            });
          }
        }

        // Group DateTime For เลือกแบบวัน
        let group = this.filterEmtyData.reduce((r, a) => {
          r[a.dateformat] = [...(r[a.dateformat] || []), a];
          return r;
        }, {});
        const result_group = Object.keys(group).map(function (k) {
          const item = group[k];
          return {
            datetxtShow: item[0].dateformat,
          };
        });

        this.datetimeShowList = result_group;

        // ---------------------------------------- หา min max หาเฉลี่ย
        if(this.dataCalculateBloodPre.length > 0){
        this.minBloodPreValue = Math.min.apply(
          Math,
          this.dataChartBloodPre.map(function (o) {
            return o.value1;
          })
        );
        this.maxBloodPreValue = Math.max.apply(
          Math,
          this.dataChartBloodPre.map(function (o) {
            return o.value1;
          })
        );

        this.minBloodPreValue_2 = Math.min.apply(
          Math,
          this.dataChartBloodPre.map(function (o) {
            return o.value2;
          })
        );
        this.maxBloodPreValue_2 = Math.max.apply(
          Math,
          this.dataChartBloodPre.map(function (o) {
            return o.value2;
          })
        );

        var total = 0;
        var total_2 = 0;

        for (var i = 0; i < this.dataChartBloodPre.length; i++) {
          total += this.dataChartBloodPre[i].value1;
          total_2 += this.dataChartBloodPre[i].value2;
        }

        this.averBloodPreValue = total / this.dataChartBloodPre.length;
        this.averBloodPreValue_2 = total_2 / this.dataChartBloodPre.length;
      }




        // // หา 24 ชม. เฉลี่ย --------------------------- เอาตัวเลข time_h มา Group
        const reduced = this.dataCalculateBloodPre.reduce(function (m, d) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].value2 += d.value2;
          m[d.time_h].count += 1;
          return m;
        }, {});

        // // หาค่าเฉลี่ย
        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            time_h: item.time_h,
            avg: item.value1 / item.count,
            avg2: item.value2 / item.count,
          };
        });

        // // โชว์ค่าทั้ง 24 ชั่วโมง
        for (var i = 0; i < 24; i++) {
          var index = result.findIndex((e) => e.time_h == i);
          if (index > -1) {
            this.dataChart24List.push({
              time_h: ('00' + result[index].time_h).slice(-2) + ':00 น',
              avg: result[index].avg,
              avg2: result[index].avg2,
              num_hr: result[index].time_h,
            });
          } else {
            this.dataChart24List.push({
              time_h: ('00' + i).slice(-2) + ':00 น',
              avg: 0,
              avg2: 0,
              num_hr: i,
            });
          }
        }

        console.log('dataChart24List AveAll', this.dataChart24List);

        // // --------------------------------- หาผล min กี่ครั้ง , max กี่ครั้ง
        if(this.dataCalculateBloodPre.length > 0){
        for (var i = 0; i < this.dataCalculateBloodPre.length; i++) {

          if (this.dataCalculateBloodPre[i].value1 >= 180 || this.dataCalculateBloodPre[i].value2 >= 120)
          {
            this.countvalhighcrisis++;
          }
          else if (this.dataCalculateBloodPre[i].value1 >= 140 || this.dataCalculateBloodPre[i].value2 >= 90)
          {
            this.countvalhighstate2++;
          }
          else if ((this.dataCalculateBloodPre[i].value1 >= 130 && this.dataCalculateBloodPre[i].value1 <= 139) || (this.dataCalculateBloodPre[i].value2 >= 80 && this.dataCalculateBloodPre[i].value2 <= 89))
          {
            this.countvalhighstate1++;
          }
          else if (this.dataCalculateBloodPre[i].value1 >= 120 && this.dataCalculateBloodPre[i].value1 <= 129 && this.dataCalculateBloodPre[i].value2 < 80)
          {
            this.countelevated++;
          }
          else
          {
            this.countvalNormal++;
          }

        }
      }

        // // GEN กราฟ
        this.GraphBloodPreDemo();
        this.GraphBloodPreNew();
        this.GraphAver24hLine(this.dataChart24List);
        this.GraphValPercen();
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  loadChartbyDateFormAPI(imeiCode: string, datetimes: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_DayBloodPre?imeicode=${imeiCode}&date=${datetimes}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  async loadDatabyDateChart(imeiCode: string, datetimes: string) {
    await this.loadChartbyDateFormAPI(imeiCode, datetimes).then(
      (res: any) => {
        this.dataChart24List_Day1 = [];
        this.dataCalculateBloodPre_Day1 = [];

        for (let i = 0; i < res.length; i++) {
          this.dataCalculateBloodPre_Day1.push({
            date: new Date(res[i].time_log),
            time_h: new Date(
              formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
            ).getHours(),
            time_day: new Date(
              formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
            ).getDay(),
            value1: Number(res[i].blood_pressure_SBP),
            value2: Number(res[i].blood_pressure_DBP),
          });
        }
        this.dataCalculateBloodPre_Day1 = this.dataCalculateBloodPre_Day1.map((item: any) => ({
          ...item,
          date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
          time_h: item.time_h - 7
        }));

        const reduced = this.dataCalculateBloodPre_Day1.reduce(function (m, d) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].value2 += d.value2;
          m[d.time_h].count += 1;
          return m;
        }, {});

        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            time_h: item.time_h,
            avg: item.value1 / item.count,
            avg2: item.value2 / item.count,
          };
        });

        // โชว์ค่าทั้ง 24 ชั่วโมง
        for (var i = 0; i < 24; i++) {
          var index = result.findIndex((e) => e.time_h == i);
          if (index > -1) {
            this.dataChart24List_Day1.push({
              time_h: ('00' + result[index].time_h).slice(-2) + ':00 น',
              avg: result[index].avg,
              avg2: result[index].avg2,
              num_hr: result[index].time_h,
            });
          } else {
            this.dataChart24List_Day1.push({
              time_h: ('00' + i).slice(-2) + ':00 น',
              avg: 0,
              avg2: 0,
              num_hr: i,
            });
          }
        }

        this.GraphAver24hLine(this.dataChart24List_Day1);
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  GraphBloodPreDemo() {
    this.changeDetectRef.detectChanges();

    am4core.useTheme(am4themes_animated);
    // console.log('this.dataChartBloodPre : ', this.dataChartBloodPre);

    // Create chart instance
    var chart = am4core.create(
      this.chartBloodPre.nativeElement,
      am4charts.XYChart
    );
    chart.exporting.menu = new am4core.ExportMenu();
    this.dataChartBloodPre = this.dataChartBloodPre.map((item: any) => ({
      ...item,
      date: this.datePipe.transform(item.date, "dd-MM-yyyy H:mm")
    }));
    chart.data = this.dataChartBloodPre;

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color('#243b55'));
    gradient.addColor(am4core.color('#141e30'));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    categoryAxis.renderer.labels.template.fill = am4core.color('#ffffff');

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 40;
    valueAxis.max = 160;
    valueAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    valueAxis.renderer.labels.template.fill = am4core.color('#ffffff');

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value1';
    series.dataFields.categoryX = 'date';
    series.strokeWidth = 1.5;
    series.minBulletDistance = 10;
    series.stroke = am4core.color('#64dbfb');
    series.tooltipText = '[bold]{date}:[/] Systolic : {value1}\n[bold]';

    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = 'value2';
    series2.dataFields.categoryX = 'date';
    series2.strokeWidth = 1.5;
    series2.minBulletDistance = 10;
    series2.stroke = am4core.color('#ff5566');
    series2.tooltipText = '[bold]{date}:[/] Diastolic : {value2}\n[bold]';

    var axisRangetop = valueAxis.axisRanges.create();
    axisRangetop.value = this.averBloodPreValue;
    axisRangetop.grid.strokeOpacity = 1;
    axisRangetop.grid.stroke = am4core.color('#00ba00');
    axisRangetop.grid.strokeWidth = 1.5;

    var axisRangebottom = valueAxis.axisRanges.create();
    axisRangebottom.value = this.averBloodPreValue_2;
    axisRangebottom.grid.strokeOpacity = 1;
    axisRangebottom.grid.stroke = am4core.color('#00ba00');
    axisRangebottom.grid.strokeWidth = 1.5;

    var rangeareatop = valueAxis.axisRanges.create();
    rangeareatop.value = 120;
    rangeareatop.endValue = 80;
    rangeareatop.axisFill.fill = am4core.color('#64dbfb');
    rangeareatop.axisFill.fillOpacity = 0.2;
    rangeareatop.grid.strokeOpacity = 0;

    var rangeareabottom = valueAxis.axisRanges.create();
    rangeareabottom.value = 60;
    rangeareabottom.endValue = 80;
    rangeareabottom.axisFill.fill = am4core.color('#ff5566');
    rangeareabottom.axisFill.fillOpacity = 0.2;
    rangeareabottom.grid.strokeOpacity = 0;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }

  GraphBloodPreNew() {
    var newColor = [];

    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(
      this.chartBloodPreNew.nativeElement,
      am4charts.XYChart
    );
    chart.exporting.menu = new am4core.ExportMenu();

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color('#141e30'));
    gradient.addColor(am4core.color('#243b55'));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    for (var i = 0; i < this.dataChartBloodPre.length; i++) {
      var sum_2value = 0;
      sum_2value =
        this.dataChartBloodPre[i].value1 - this.dataChartBloodPre[i].value2;

      if (sum_2value >= 20) {
        newColor.push({
          date: this.dataChartBloodPre[i].date,
          dateformat: this.dataChartBloodPre[i].dateformat,
          value1: sum_2value,
          color: am4core.color('#00ba00'),
        });
      } else {
        this.countBloodPreLow20++;
        newColor.push({
          date: this.dataChartBloodPre[i].date,
          dateformat: this.dataChartBloodPre[i].dateformat,
          value1: sum_2value,
          color: am4core.color('#ff0000'),
        });
      }
    }

    chart.data = newColor;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    categoryAxis.renderer.labels.template.fill = am4core.color('#ffffff');

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.min = 70;
    valueAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    valueAxis.renderer.labels.template.fill = am4core.color('#ffffff');

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = 'value1';
    series.dataFields.categoryX = 'date';
    series.tooltipText = '[bold]{date}:[/] {value1}\n[bold]';
    series.columns.template.strokeWidth = 0;
    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;
    series.columns.template.propertyFields.fill = 'color';
    series.tooltipPosition = 'pointer';

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }

  GraphAver24hLine(mainData: any[]) {
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(
      this.chartBloodPreAver24Line.nativeElement,
      am4charts.XYChart
    );
    chart.exporting.menu = new am4core.ExportMenu();

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color('#243b55'));
    gradient.addColor(am4core.color('#141e30'));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    let newList: any = [];

    for (var i = 0; i < mainData.length; i++) {
      newList.push({
        time_h: mainData[i].time_h,
        avg: mainData[i].avg,
        avg2: mainData[i].avg2,
        num_hr: i,
      });
    }

    chart.data = newList;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'time_h';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = 'right';
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.minHeight = 110;
    categoryAxis.renderer.grid.template.stroke = am4core.color('#ffffff');
    categoryAxis.renderer.labels.template.fill = am4core.color('#ffffff');

    let dateAxis2 = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis2.renderer.grid.template.location = 0;
    dateAxis2.renderer.minGridDistance = 40;
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.grid.template.disabled = true;
    dateAxis2.cursorTooltipEnabled = false;

    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.grid.template.stroke = am4core.color('#ffffff');
    valueAxis2.renderer.labels.template.fill = am4core.color('#ffffff');
    valueAxis2.min = 40;
    valueAxis2.max = 160;

    var rangeareatop = valueAxis2.axisRanges.create();
    rangeareatop.value = 120;
    rangeareatop.endValue = 80;
    rangeareatop.axisFill.fill = am4core.color('#64dbfb');
    rangeareatop.axisFill.fillOpacity = 0.2;
    rangeareatop.grid.strokeOpacity = 0;

    var rangeareabottom = valueAxis2.axisRanges.create();
    rangeareabottom.value = 60;
    rangeareabottom.endValue = 80;
    rangeareabottom.axisFill.fill = am4core.color('#ff5566');
    rangeareabottom.axisFill.fillOpacity = 0.2;
    rangeareabottom.grid.strokeOpacity = 0;

    let series2_sys = chart.series.push(new am4charts.LineSeries());
    series2_sys.dataFields.valueY = 'avg';
    series2_sys.dataFields.dateX = 'num_hr';
    series2_sys.data = this.dataChart24List;
    series2_sys.xAxis = dateAxis2;
    series2_sys.yAxis = valueAxis2;
    series2_sys.strokeWidth = 3;
    series2_sys.tooltipText =
      "Systolic Average : [bold]{valueY.formatNumber('#.00')}[/]";
    series2_sys.stroke = am4core.color('#00ba00');
    series2_sys.fill = am4core.color('#00ba00');

    let series1_sys = chart.series.push(new am4charts.LineSeries());
    series1_sys.dataFields.valueY = 'avg';
    series1_sys.dataFields.dateX = 'num_hr';
    series1_sys.data = newList;
    series1_sys.xAxis = dateAxis2;
    series1_sys.yAxis = valueAxis2;
    series1_sys.strokeWidth = 2;
    series1_sys.tooltipText =
      "Systolic ปัจจุบัน [bold]{valueY.formatNumber('#.00')}[/]";
    series1_sys.stroke = am4core.color('#64dbfb');
    series1_sys.fill = am4core.color('#64dbfb');

    let series2_dia = chart.series.push(new am4charts.LineSeries());
    series2_dia.dataFields.valueY = 'avg2';
    series2_dia.dataFields.dateX = 'num_hr';
    series2_dia.data = this.dataChart24List;
    series2_dia.xAxis = dateAxis2;
    series2_dia.yAxis = valueAxis2;
    series2_dia.strokeWidth = 3;
    series2_dia.tooltipText =
      "Diastolic Average : [bold]{valueY.formatNumber('#.00')}[/]";
    series2_dia.stroke = am4core.color('#00ba00');
    series2_dia.fill = am4core.color('#00ba00');

    let series1_dia = chart.series.push(new am4charts.LineSeries());
    series1_dia.dataFields.valueY = 'avg2';
    series1_dia.dataFields.dateX = 'num_hr';
    series1_dia.data = newList;
    series1_dia.xAxis = dateAxis2;
    series1_dia.yAxis = valueAxis2;
    series1_dia.strokeWidth = 2;
    series1_dia.tooltipText =
      "Diastolic ปัจจุบัน [bold]{valueY.formatNumber('#.00')}[/]";
    series1_dia.stroke = am4core.color('#ff5566');
    series1_dia.fill = am4core.color('#ff5566');

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
  }

  async loadDatabyDateChartAndReturn(datetimes: string) {
    var objList: any = [];

    await this.loadChartbyDateFormAPI(this.gimeiCode, datetimes).then(
      (res: any) => {
        var List_Day1: any = [];
        var BloodPre_Day1: any = [];

        for (let i = 0; i < res.length; i++) {
          BloodPre_Day1.push({
            date: new Date(res[i].time_log),
            time_h: new Date(
              formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
            ).getHours(),
            time_day: new Date(
              formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')
            ).getDay(),
            value1: Number(res[i].BloodPre_val),
          });
        }

        const reduced = BloodPre_Day1.reduce(function (m: any, d: any) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].count += 1;
          return m;
        }, {});

        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            time_h: item.time_h,
            avg: item.value1 / item.count,
          };
        });

        // โชว์ค่าทั้ง 24 ชั่วโมง
        for (var i = 0; i < 24; i++) {
          var index = result.findIndex((e) => e.time_h == i);
          if (index > -1) {
            List_Day1.push({
              time_h: ('00' + result[index].time_h).slice(-2) + ':00 น',
              avg: result[index].avg,
              num_hr: result[index].time_h,
            });
          } else {
            List_Day1.push({
              time_h: ('00' + i).slice(-2) + ':00 น',
              avg: 0,
              num_hr: i,
            });
          }
        }

        objList.push({
          time_day: datetimes,
          data_list: List_Day1,
        });
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );

    return objList;
  }

  GraphValPercen() {
    var val1 = (this.countvalNormal / this.dataChartBloodPre.length) * 100;
    var val2 = (this.countelevated / this.dataChartBloodPre.length) * 100;
    var val3 = (this.countvalhighstate1 / this.dataChartBloodPre.length) * 100;
    var val4 = (this.countvalhighstate2 / this.dataChartBloodPre.length) * 100;
    var val5 = (this.countvalhighcrisis / this.dataChartBloodPre.length) * 100;

    am4core.useTheme(am4themes_animated);
    // Themes end

    var chart = am4core.create(
      this.chartBloodPreValPercen.nativeElement,
      am4charts.XYChart
    );
    chart.exporting.menu = new am4core.ExportMenu();

    chart.data = [
      {
        country: 'NORMAL',
        visits: val1.toFixed(2),
        color: am4core.color('#00ba00'),
      },
      {
        country: 'ELEVATED',
        visits: val2.toFixed(2),
        color: am4core.color('#ffd200'),
      },
      {
        country: 'HIGH BLOOD PRESSURE STAGE 1',
        visits: val3.toFixed(2),
        color: am4core.color('#ffb600'),
      },
      {
        country: 'HIGH BLOOD PRESSURE STAGE 2',
        visits: val4.toFixed(2),
        color: am4core.color('#bb3a03'),
      },
      {
        country: 'HYPERTENSIVE CRISIS',
        visits: val5.toFixed(2),
        color: am4core.color('#bb3a03'),
      }
    ];

    //create category axis for years
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'country';
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;

    //create value axis for income and expenses
    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;

    //create columns
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = 'country';
    series.dataFields.valueX = 'visits';
    series.name = 'BloodPre';
    // series.columns.template.fillOpacity = 0.5;
    series.columns.template.strokeOpacity = 0;
    series.tooltipText = "{categoryY} : {valueX.value.formatNumber('#.00')}%";
    series.columns.template.propertyFields.fill = 'color';

    //add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = 'zoomY';

    //add legend
    // chart.legend = new am4charts.Legend();
  }

  // selectIMEI_onChange(deviceValue: any) {
  //   var splitimei = deviceValue.split(' ')[0];

  //   console.log('deviceValue ', splitimei);
  //   this.loadDataChart(splitimei);
  // }

  onChangeDateTimeEvent(event: any, isFirst: boolean) {
    if (!isFirst) var datetimeStr = formatDate(event.value, 'yyyy-MM-dd', 'en-US').toString();
    else var datetimeStr = formatDate(event, 'yyyy-MM-dd', 'en-US').toString();
    this.loadDatabyDateChart(this.gimeiCode, datetimeStr);
    this.loadDataTickerLogToTable(this.gimeiCode, datetimeStr);
  }

  async loadDataTickerLogToTable(imeiCode: string, datetimes: string) {
    await this.loadTickerLog(imeiCode, datetimes).then(
      (resq: any) => {
        this.tickerDataToTableList = [];
        this.tickerDataToTableList = resq;
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      }
    );
  }

  loadTickerLog(imeiCode: string, datetimes: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_ticker/getlogTickerList?imei=${imeiCode}&type=${this.pageGetDatatype}&date=${datetimes}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: 'json' })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
