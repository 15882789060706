import { Injectable } from '@angular/core';

@Injectable()
export class GlobalConstants {

    public dataSos: any =[];

    // public static imageUrl1: string = "https://api.medilifewellness.com:1243";
    // public static imageUrl2: string = "https://api.medilifewellness.com:2443";
    // public static imageUrl3: string = "https://api.medilifewellness.com:2477";
    // public static unknownUrl: string = "https://api.medilifewellness.com:3143";
    // public static socket: string = "https://connect.medilifewellness.com:2422";
    // public static doctorUrl: string = "https://dashboard.medilifewellness.com";
    // public static reportUrl: string = "https://personal.medilifewellness.com";

    public static imageUrl1: string = "https://rl-1243.achatsocial.com"; // 1243 webcore
    public static imageUrl2: string = "https://rl-2443.achatsocial.com"; // 2443 achat_health
    public static imageUrl3: string = "https://rl-2477.achatsocial.com"; // 2477 tele
    public static socket: string = "https://rl-con.achatsocial.com"; // 2022 socket

    public static doctorUrl: string = "https://rl-dash.achatsocial.com"; // tele dashboard

    public static unknownUrl: string = "https://api.medilifewellness.com:3143"; // TrickerServer

    public static reportUrl: string = "https://rl-personal.achatsocial.com"; // personal health

    // public static socket: string = "https://uat-socket.medilifewellness.com";
}
