import { ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { formatDate, DatePipe } from '@angular/common';
import { SharedServiceService } from 'src/app/common/shared-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-stress',
  templateUrl: './stress.component.html',
  styleUrls: ['./stress.component.scss']
})
export class StressComponent implements OnInit {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  ipServer: string = GlobalConstants.imageUrl2;
  // ipServer: string = "https://localhost:44378";


  imeiList: any[] = [];

  dataChartStress: any[] = [];
  dataCalculateStress: any[] = [];
  dataChart24List: any[] = [];
  dataChart7DayList: any[] = [];
  countChartData = 1;


  dataChart24List_Day1: any[] = [];
  dataCalculateStress_Day1: any[] = [];


  filterEmtyData: any[] = [];
  datetimeShowList: any[] = [];


  minStressValue: any = 0;
  maxStressValue: any = 0;
  averStressValue: any = 0;

  countGood: any = 0;
  countMid: any = 0;
  countBad: any = 0;

  gimeiCode: any = "";

  rangeDateStartAndEnd = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date()),
  });
  setNow: any = new Date();


  @ViewChild('chartStress', { static: false }) chartStress!: ElementRef;
  @ViewChild('chartStressColl', { static: false }) chartStressColl!: ElementRef;
  @ViewChild('chartStressAver24Line', { static: false }) chartStressAver24Line!: ElementRef;

  @ViewChild('chartStressAver7day', { static: false }) chartStressAver7day!: ElementRef;

  @ViewChild('chartStressValPercen', { static: false }) chartStressValPercen!: ElementRef;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private changeDetectRef:ChangeDetectorRef,private sharedServiceService: SharedServiceService,@Inject(LOCALE_ID) private locale: string) {

  }


  ngOnInit(): void {
    // this.loadIMEIListData();
    this.sharedServiceService.deviceIDValue.subscribe(
      (swimei) => {
        // console.log("message ID ", message);
        // this.loadDataChart(swimei);
        var now = new Date();

        var nowStart = formatDate(now, 'yyyy-MM-dd', this.locale);
        var nowEnd = formatDate(now.setDate(now.getDate() + 1), 'yyyy-MM-dd', this.locale);
        this.loadDataChart(swimei, nowStart, nowEnd);
        let dateNow = this.datePipe.transform(new Date(), "yyyy-MM-dd");
        setTimeout(async () => {
          await this.onChangeDateTimeEvent(dateNow, true);
        }, 1500);
      }
    );
  }

  onChangeDate() {
    var dataStart: string = '';
    var dataEnd: string = '';

    dataStart = this.rangeDateStartAndEnd.value.start;
    dataEnd = this.rangeDateStartAndEnd.value.end;

    if (this.rangeDateStartAndEnd.value.start != null && this.rangeDateStartAndEnd.value.end != null) {
      var dataStart_ = formatDate(dataStart, 'yyyy-MM-dd', this.locale);
      var dataEnd_ = formatDate(dataEnd, 'yyyy-MM-dd', this.locale);

      var newEnd = new Date(dataEnd);
      var newEndAddDay = newEnd.setDate(newEnd.getDate() + 1);
      dataEnd_ = formatDate(newEndAddDay, 'yyyy-MM-dd', this.locale);
      console.log("dataStart_ ", dataStart_, " | dataEnd_ ", dataEnd_);

      this.loadDataChart(this.gimeiCode, dataStart_, dataEnd_);
    }
  }

  loadIMEIListFormAPI() {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_Stress_CollectionList`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }

  async loadIMEIListData() {
    await this.loadIMEIListFormAPI().then(
      (res: any) => {
        console.log(res);
        this.imeiList = res;
      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }


  loadChartFormAPI(imeiCode: string, dateStart: string, dateEnd: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_LogStressDateFilterList?imeicode=${imeiCode}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }

  async loadDataChart(imeiCode: string, dateStart: string, dateEnd: string) {
    await this.loadChartFormAPI(imeiCode, dateStart, dateEnd).then(
      (resq: any) => {

        console.log("resq -------------> ", resq);
        this.countChartData = resq.length;

        var res = [];

        this.filterEmtyData = [];
        this.datetimeShowList = [];


        this.gimeiCode = imeiCode;
        this.dataCalculateStress = [];
        this.dataChartStress = [];
        this.dataChart24List = [];
        this.dataChart7DayList = [];
        this.minStressValue = 0;
        this.maxStressValue = 0;
        this.averStressValue = 0;



        this.countGood = 0;
        this.countBad = 0;
        this.countMid = 0;




        for (var i = 0; i < resq.length; i++) {
          if (resq[i].steress_val > 0) {
            res.push({
              id: resq[i].id,
              imeicode: resq[i].imeicode,
              steress_val: resq[i].steress_val,
              stress_status: resq[i].stress_status,
              time_log: resq[i].time_log
            });
          }
        }

        console.log("res -------------> ", res);



        // res.sort(function (a: any, b: any) {
        //   return + new Date(a.time_log) - + new Date(b.time_log);
        // });


        for (let i = 0; i < res.length; i++) {
          this.dataChartStress.push({
            // date: formatDate(
            //   res[i].time_log,
            //   'yyyy/MM/dd hh:mm',
            //   'en-US'
            // ),
        date: res[i].time_log.split("T")[0]+" "+res[i].time_log.split("T")[1].substring(0,5),

            dateformat: new Date(formatDate(
              res[i].time_log,
              'yyyy/MM/dd',
              'en-US'
            )).toLocaleDateString('en-US'),
            value1: Number(res[i].steress_val),
            value2: res[i].stress_status,
          });

          this.dataCalculateStress.push({
            date: new Date(res[i].time_log),
            time_h: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getHours(),
            time_day: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getDay(),
            value1: Number(res[i].steress_val),
            value2: res[i].stress_status,
          });

        }
        this.dataCalculateStress = this.dataCalculateStress.map((item: any) => ({
          ...item,
          date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
          time_h: item.time_h - 7
        }));


        this.dataChartStress.sort(function (a: any, b: any) {
          return + new Date(a.date) - + new Date(b.date);
        });


        for (var i = 0; i < this.dataChartStress.length; i++) {
          if (this.dataChartStress[i].value1 != 0 && this.dataChartStress[i].value1 != NaN) {
            this.filterEmtyData.push({
              dateformat: this.dataChartStress[i].dateformat
            });
          }
        }

        // Group DateTime For เลือกแบบวัน
        let group = this.filterEmtyData.reduce((r, a) => {
          r[a.dateformat] = [...r[a.dateformat] || [], a];
          return r;
        }, {});
        const result_group = Object.keys(group).map(function (k) {
          const item = group[k];
          return {
            datetxtShow: item[0].dateformat
          }
        });

        this.datetimeShowList = result_group;
        // console.log("Group datetimeShowList ", this.datetimeShowList);











        // ---------------------------------------- หา min max หาเฉลี่ย
        if(this.dataCalculateStress.length > 0){
        this.minStressValue = Math.min.apply(Math, this.dataCalculateStress.map(function (o) { return o.value1; }));
        this.maxStressValue = Math.max.apply(Math, this.dataCalculateStress.map(function (o) { return o.value1; }));
        var total = 0;
        for (var i = 0; i < this.dataCalculateStress.length; i++) {
          total += this.dataCalculateStress[i].value1;
        }
        this.averStressValue = total / this.dataCalculateStress.length;
      }



        // หา 24 ชม. เฉลี่ย --------------------------- เอาตัวเลข time_h มา Group
        const reduced = this.dataCalculateStress.reduce(function (m, d) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].count += 1;
          return m;
        }, {});



        // หาค่าเฉลี่ย
        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            time_h: item.time_h,
            avg: item.value1 / item.count,
          }
        });



        // โชว์ค่าทั้ง 24 ชั่วโมง
        for (var i = 0; i < 24; i++) {
          var index = result.findIndex(e => e.time_h == i);
          if (index > -1) {
            this.dataChart24List.push({
              time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
              avg: result[index].avg,
              num_hr: result[index].time_h
            });
          } else {
            this.dataChart24List.push({
              time_h: ("00" + i).slice(-2) + ":00 น",
              avg: 0,
              num_hr: i
            });
          }
        }

        console.log("dataChart24List AveAll", this.dataChart24List);


        // --------------------------------- หาผล min กี่ครั้ง , max กี่ครั้ง
        if(this.dataCalculateStress.length > 0){
        for (var i = 0; i < this.dataCalculateStress.length; i++) {
          if (this.dataCalculateStress[i].value2 == "good") {
            this.countGood++;
          } else if (this.dataCalculateStress[i].value2 == "middle") {
            this.countMid++;
          } else {
            this.countBad++;
          }
        }
      }




        // หา 7 วันเฉลี่ย --------------------- เอาตัวเลข time_day มา Group
        const reduced_day = this.dataCalculateStress.reduce(function (m, d) {
          if (!m[d.time_day]) {
            m[d.time_day] = { ...d, count: 1 };
            return m;
          }
          m[d.time_day].value1 += d.value1;
          m[d.time_day].count += 1;
          return m;
        }, {});

        // หาค่าเฉลี่ย
        const result_day = Object.keys(reduced_day).map(function (k) {
          const item = reduced_day[k];

          var daynames = "";

          switch (item.time_day) {
            case 0:
              daynames = "วันอาทิตย์";
              break;
            case 1:
              daynames = "วันจันทร์";
              break;
            case 2:
              daynames = "วันอังคาร";
              break;
            case 3:
              daynames = "วันพุธ";
              break;
            case 4:
              daynames = "วันพฤหัส";
              break;
            case 5:
              daynames = "วันศุกร์";
              break;
            default:
              daynames = "วันเสาร์";
              break;
          }

          return {
            time_day: daynames,
            avg: item.value1 / item.count,
          }
        });

        this.dataChart7DayList = result_day;





        // GEN กราฟ
        this.GraphStressDemo();
        this.GraphStressCollDemo();

        this.GraphAver24hLine(this.dataChart24List);
        this.GraphAver7days();
        this.GraphValPercen();

      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }







  loadChartbyDateFormAPI(imeiCode: string, datetimes: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${this.ipServer}/api/smartwatch_cn_api/getCN_DayStress?imeicode=${imeiCode}&date=${datetimes}`;
      this.httpClient.get(API_URL, { headers: this.httpHeaders, responseType: "json" }).subscribe(data => {
        resolve(data);
      },
        error => {
          reject(error);
        });
    });
  }


  async loadDatabyDateChart(imeiCode: string, datetimes: string) {
    await this.loadChartbyDateFormAPI(imeiCode, datetimes).then(
      (res: any) => {

        this.dataChart24List_Day1 = [];
        this.dataCalculateStress_Day1 = [];

        for (let i = 0; i < res.length; i++) {
          this.dataCalculateStress_Day1.push({
            date: new Date(res[i].time_log),
            time_h: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getHours(),
            time_day: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getDay(),
            value1: Number(res[i].steress_val),
            value2: res[i].stress_status,
          });
        }
        this.dataCalculateStress_Day1 = this.dataCalculateStress_Day1.map((item: any) => ({
          ...item,
          date: new Date(new Date(item.date).getTime() - (1000 * 60 * 60 * 7)),
          time_h: item.time_h - 7
        }));

        const reduced = this.dataCalculateStress_Day1.reduce(function (m, d) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].count += 1;
          return m;
        }, {});

        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            time_h: item.time_h,
            avg: item.value1 / item.count,
          }
        });


        // โชว์ค่าทั้ง 24 ชั่วโมง
        for (var i = 0; i < 24; i++) {
          var index = result.findIndex(e => e.time_h == i);
          if (index > -1) {
            this.dataChart24List_Day1.push({
              time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
              avg: result[index].avg,
              num_hr: result[index].time_h
            });
          } else {
            this.dataChart24List_Day1.push({
              time_h: ("00" + i).slice(-2) + ":00 น",
              avg: 0,
              num_hr: i
            });
          }
        }

        this.GraphAver24hLine(this.dataChart24List_Day1);

      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      })
  }










  GraphStressDemo() {
    this.changeDetectRef.detectChanges();

    am4core.useTheme(am4themes_animated);

    // Create chart instance
    var chart = am4core.create(this.chartStress.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();
    this.dataChartStress = this.dataChartStress.map((item: any) => ({
      ...item,
      date: this.datePipe.transform(item.date, "dd-MM-yyyy H:mm")
    }));
    chart.data = this.dataChartStress;


    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color("#243b55"));
    gradient.addColor(am4core.color("#141e30"));
    gradient.rotation = 90;
    chart.background.fill = gradient;


    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 40;
    valueAxis.max = 80;
    valueAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");



    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value1';
    series.dataFields.categoryX = 'date';
    series.strokeWidth = 1;
    series.minBulletDistance = 10;
    series.stroke = am4core.color('#64dbfb');
    series.tooltipText = '[bold]{date}:[/] {value1}\n[bold]';



    var axisRangegood = valueAxis.axisRanges.create();
    axisRangegood.value = 58;
    axisRangegood.grid.strokeOpacity = 1;
    axisRangegood.grid.stroke = am4core.color('#00ba00');
    axisRangegood.grid.strokeWidth = 1;

    var axisRangebad = valueAxis.axisRanges.create();
    axisRangebad.value = 68;
    axisRangebad.grid.strokeOpacity = 1;
    axisRangebad.grid.stroke = am4core.color('#f3260e');
    axisRangebad.grid.strokeWidth = 1;

    var rangeareamid = valueAxis.axisRanges.create();
    rangeareamid.value = 58;
    rangeareamid.endValue = 68;
    rangeareamid.axisFill.fill = am4core.color('#fcff01');
    rangeareamid.axisFill.fillOpacity = 0.2;
    rangeareamid.grid.strokeOpacity = 0;

    var rangeareagood = valueAxis.axisRanges.create();
    rangeareagood.value = 40;
    rangeareagood.endValue = 58;
    rangeareagood.axisFill.fill = am4core.color('#00ba00');
    rangeareagood.axisFill.fillOpacity = 0.2;
    rangeareagood.grid.strokeOpacity = 0;

    var rangeareabad = valueAxis.axisRanges.create();
    rangeareabad.value = 68;
    rangeareabad.endValue = 80;
    rangeareabad.axisFill.fill = am4core.color('#f3260e');
    rangeareabad.axisFill.fillOpacity = 0.2;
    rangeareabad.grid.strokeOpacity = 0;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }









  GraphStressCollDemo() {

    var newColor = [];

    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(this.chartStressColl.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color("#243b55"));
    gradient.addColor(am4core.color("#141e30"));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    for (var i = 0; i < this.dataChartStress.length; i++) {
      if (this.dataChartStress[i].value1 < 58) {
        newColor.push({
          date: this.dataChartStress[i].date,
          dateformat: this.dataChartStress[i].dateformat,
          value1: this.dataChartStress[i].value1,
          "color": am4core.color("#00ba00")
        })
      } else if (this.dataChartStress[i].value1 >= 58 && this.dataChartStress[i].value1 <= 68) {
        newColor.push({
          date: this.dataChartStress[i].date,
          dateformat: this.dataChartStress[i].dateformat,
          value1: this.dataChartStress[i].value1,
          "color": am4core.color("#ffab00")
        })
      } else {
        newColor.push({
          date: this.dataChartStress[i].date,
          dateformat: this.dataChartStress[i].dateformat,
          value1: this.dataChartStress[i].value1,
          "color": am4core.color("#ff0000")
        })
      }
    }

    chart.data = newColor;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 300;
    categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.min = 70;
    valueAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    valueAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "value1";
    series.dataFields.categoryX = "date";
    series.tooltipText = "[bold]{date}:[/] {value1}\n[bold]";
    series.columns.template.strokeWidth = 0;
    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;
    series.columns.template.propertyFields.fill = "color";
    series.tooltipPosition = "pointer";

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
  }








  GraphAver24hLine(mainData: any[]) {

    am4core.useTheme(am4themes_animated);

    var chart = am4core.create(this.chartStressAver24Line.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color("#243b55"));
    gradient.addColor(am4core.color("#141e30"));
    gradient.rotation = 90;
    chart.background.fill = gradient;

    let newList: any = [];

    for (var i = 0; i < mainData.length; i++) {
      newList.push({
        time_h: mainData[i].time_h,
        avg: mainData[i].avg,
        num_hr: i
      })
    }

    chart.data = newList;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "time_h";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.minHeight = 110;
    categoryAxis.renderer.grid.template.stroke = am4core.color("#ffffff");
    categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");

    let dateAxis2 = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis2.renderer.grid.template.location = 0;
    dateAxis2.renderer.minGridDistance = 40;
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.grid.template.disabled = true;
    dateAxis2.cursorTooltipEnabled = false;

    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.grid.template.stroke = am4core.color("#ffffff");
    valueAxis2.renderer.labels.template.fill = am4core.color("#ffffff");
    valueAxis2.min = 40;
    valueAxis2.max = 80;

    var axisRangegood = valueAxis2.axisRanges.create();
    axisRangegood.value = 58;
    axisRangegood.grid.strokeOpacity = 1;
    axisRangegood.grid.stroke = am4core.color('#00ba00');
    axisRangegood.grid.strokeWidth = 1;

    var axisRangebad = valueAxis2.axisRanges.create();
    axisRangebad.value = 68;
    axisRangebad.grid.strokeOpacity = 1;
    axisRangebad.grid.stroke = am4core.color('#f3260e');
    axisRangebad.grid.strokeWidth = 1;

    var rangeareamid = valueAxis2.axisRanges.create();
    rangeareamid.value = 58;
    rangeareamid.endValue = 68;
    rangeareamid.axisFill.fill = am4core.color('#fcff01');
    rangeareamid.axisFill.fillOpacity = 0.2;
    rangeareamid.grid.strokeOpacity = 0;

    var rangeareagood = valueAxis2.axisRanges.create();
    rangeareagood.value = 40;
    rangeareagood.endValue = 58;
    rangeareagood.axisFill.fill = am4core.color('#00ba00');
    rangeareagood.axisFill.fillOpacity = 0.2;
    rangeareagood.grid.strokeOpacity = 0;

    var rangeareabad = valueAxis2.axisRanges.create();
    rangeareabad.value = 68;
    rangeareabad.endValue = 80;
    rangeareabad.axisFill.fill = am4core.color('#f3260e');
    rangeareabad.axisFill.fillOpacity = 0.2;
    rangeareabad.grid.strokeOpacity = 0;

    let series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "avg";
    series2.dataFields.dateX = "num_hr";
    series2.data = this.dataChart24List;
    series2.xAxis = dateAxis2;
    series2.yAxis = valueAxis2;
    series2.strokeWidth = 3;
    series2.tooltipText = "Average : [bold]{valueY.formatNumber('#.00')}[/]%";
    series2.stroke = am4core.color('#cd0506');
    series2.fill = am4core.color('#cd0506');


    let series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.valueY = "avg";
    series1.dataFields.dateX = "num_hr";
    series1.data = newList;
    series1.xAxis = dateAxis2;
    series1.yAxis = valueAxis2;
    series1.strokeWidth = 2;
    series1.tooltipText = "ปัจจุบัน [bold]{valueY.formatNumber('#.00')}[/]%";
    series1.stroke = am4core.color('#00bce6');
    series1.fill = am4core.color('#00bce6');


    // Add cursor
    chart.cursor = new am4charts.XYCursor();

  }




  async loadDatabyDateChartAndReturn(datetimes: string) {

    var objList: any = [];

    await this.loadChartbyDateFormAPI(this.gimeiCode, datetimes).then(
      (res: any) => {

        var List_Day1: any = [];
        var Stress_Day1: any = [];

        for (let i = 0; i < res.length; i++) {
          Stress_Day1.push({
            date: new Date(res[i].time_log),
            time_h: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getHours(),
            time_day: new Date(formatDate(res[i].time_log, 'yyyy/MM/dd HH:MM', 'en-US')).getDay(),
            value1: Number(res[i].steress_val),
            value2: res[i].stress_status,
          });
        }

        const reduced = Stress_Day1.reduce(function (m: any, d: any) {
          if (!m[d.time_h]) {
            m[d.time_h] = { ...d, count: 1 };
            return m;
          }
          m[d.time_h].value1 += d.value1;
          m[d.time_h].count += 1;
          return m;
        }, {});

        const result = Object.keys(reduced).map(function (k) {
          const item = reduced[k];
          return {
            time_h: item.time_h,
            avg: item.value1 / item.count,
          }
        });


        // โชว์ค่าทั้ง 24 ชั่วโมง
        for (var i = 0; i < 24; i++) {
          var index = result.findIndex(e => e.time_h == i);
          if (index > -1) {
            List_Day1.push({
              time_h: ("00" + result[index].time_h).slice(-2) + ":00 น",
              avg: result[index].avg,
              num_hr: result[index].time_h
            });
          } else {
            List_Day1.push({
              time_h: ("00" + i).slice(-2) + ":00 น",
              avg: 0,
              num_hr: i
            });
          }
        }


        objList.push({
          time_day: datetimes,
          data_list: List_Day1
        });

      },
      (error) => {
        console.log(error.status + ' ' + error.statusText);
      });

    return objList;

  }





  GraphAver7days() {

    am4core.useTheme(am4themes_animated);
    // Themes end

    var chart = am4core.create(this.chartStressAver7day.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    // Add data
    chart.data = this.dataChart7DayList;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "time_day";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.minHeight = 110;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "avg";
    series.dataFields.categoryX = "time_day";
    series.tooltipText = "[{categoryX}: bold]{valueY.formatNumber('#.00')}[/]";
    series.columns.template.strokeWidth = 0;
    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;
    series.fill = am4core.color("#514ab8");

    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

    // Cursor
    chart.cursor = new am4charts.XYCursor();
  }








  GraphValPercen() {

    var val1 = (this.countGood / this.dataChartStress.length) * 100;
    var val2 = (this.countMid / this.dataChartStress.length) * 100;
    var val3 = (this.countBad / this.dataChartStress.length) * 100;

    am4core.useTheme(am4themes_animated);
    // Themes end

    var chart = am4core.create(this.chartStressValPercen.nativeElement, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    chart.data = [{
      "country": "ปกติ (น้อยกว่า 58)",
      "visits": val1.toFixed(2),
      "color": am4core.color("#00ba00")
    }, {
      "country": "ปานกลาง (58 - 68)",
      "visits": val2.toFixed(2),
      "color": am4core.color("#ffb600")
    }, {
      "country": "สูง (มากกว่า 68)",
      "visits": val3.toFixed(2),
      "color": am4core.color("#bb3a03")
    }];

    //create category axis for years
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;

    //create value axis for income and expenses
    let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;

    //create columns
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "country";
    series.dataFields.valueX = "visits";
    series.name = "Stress";
    series.columns.template.fillOpacity = 1;
    series.columns.template.strokeOpacity = 0;
    series.tooltipText = "{categoryY} : {valueX.value.formatNumber('#.00')}%";
    series.columns.template.propertyFields.fill = "color";

    //add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomY";

  }



  onChangeDateTimeEvent(event: any, isFirst: boolean) {
    if (!isFirst) var datetimeStr = formatDate(event.value, 'yyyy-MM-dd', 'en-US').toString();
    else var datetimeStr = formatDate(event, 'yyyy-MM-dd', 'en-US').toString();
    this.loadDatabyDateChart(this.gimeiCode, datetimeStr);
  }


}
