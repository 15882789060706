import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceService } from '../../../../../../core/services/service.service';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
    selector: 'dialog-success-refer-covid19',
    templateUrl: './dialog-success-refer-covid19.html',
})
export class DialogdialogSuccessReferCovid19 {

    idOperatorAdmin: any;
    dataID: any;

    constructor(
        private router: Router,
        private ServiceService: ServiceService,
        public dialogRef: MatDialogRef<DialogdialogSuccessReferCovid19>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.idOperatorAdmin = localStorage.getItem("currentIdAdmin");
        // console.log(data);
        this.dataID = data;
    }

    endCase(checkCloseCase: boolean) {
        if(this.dataID.refer == "refer"){
            if (checkCloseCase == true) {
                this.ServiceService.UpdateDataDoctorMallUser_(this.dataID.dmID, "EndTreat", this.idOperatorAdmin,this.dataID.userID).then(async resDataUpdateDoctorMallUser => {
                    // await window.open("https://localhost:44316/api/CovidSymptomV2/ViewReferUsercovid19?userId=" + this.dataID.userID);
                    await window.open(GlobalConstants.imageUrl1 + "/api/CovidSymptomV2/ViewReferUsercovid19?userId=" + this.dataID.userID);
                    this.router.navigate([`/doctorMallCovid19List`], { replaceUrl: true }).then(() => { window.location.reload(); });
                });
            } else {
                // window.open("https://localhost:44316/api/CovidSymptomV2/ViewReferUsercovid19?userId=" + this.dataID.userID);
                window.open(GlobalConstants.imageUrl1 + "/api/CovidSymptomV2/ViewReferUsercovid19?userId=" + this.dataID.userID);
                this.dialogRef.close();
            }
        }else{
            if (checkCloseCase == true) {
                this.ServiceService.UpdateDataDoctorMallUser_(this.dataID.dmID, "EndTreat", this.idOperatorAdmin,this.dataID.userID).then(async resDataUpdateDoctorMallUser => {
                 this.router.navigate([`/doctorMallCovid19List`], { replaceUrl: true }).then(() => { window.location.reload(); });
                });
            } else {
                this.dialogRef.close();
            }
        }
    }
}
