import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../../core/services/service.service';
import jwt_decode from 'jwt-decode';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WebsocketService } from '../../../core/services/websocket.service'
import { EditAdminDialog } from './editAdmin.component';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  imageUrl1: string = GlobalConstants.imageUrl1;
  imageUrl2: string = GlobalConstants.imageUrl2;
  showHeadSuperAdmin: boolean = false;
  showHeadAdminProvince: boolean = false;
  showHeadAdminDistrict: boolean = false;
  showHeadAdminSubdistricts: boolean = false;
  showHeadAdminHuawei: boolean = false;
  showProfileOperator: boolean = false;
  showAdminFilter: boolean = false;
  showAdminViewOnly: boolean = false;
  showSmartwatchTicker: boolean = false;
  showWatchStatus: boolean = false;
  showWatchManagement: boolean = false;
  showTrackingSystem: boolean = false;
  hideLiveMonitor: boolean = false;
  hideAlertOperator: boolean = false;
  hideReport: boolean = false;
  showPdf: boolean = false;
  adminId!: any;
  operatorId!: string;
  adminNamerole!: string;
  adminDistrictId!: string;
  adminParrent!: string;
  adminProvinceID!: string;

  getNameProvince: string = "";
  getProvinceId: string = "";
  getNameDistrict: string = "";
  getNameSubDistrict: string = "";
  getIdSubDistrict: string = "";

  dataAdminLoginin: any;
  idOperator!: string;
  animationItem: boolean = true;
  roleAdmin!:any;
  dataOperator:any;

  activeMenu: string = '';

  constructor(private ServiceService: ServiceService, private websocketService: WebsocketService,public dialog: MatDialog,) {
  }
  ngOnInit(): void {
    // var decode = this.getDecodedAccessToken(localStorage.getItem("currentUser"));
    // console.log("decode-------> ", decode);
    var idAdmin = localStorage.getItem("currentIdAdmin");
    var role = localStorage.getItem("currentRole");
    this.adminId = idAdmin;
    this.checkMenu(role);
    if(idAdmin != null){
      this.loadAdmin(this.adminId);
    }
    if(role == "Operator"){
      this.roleAdmin = "Releep Call Team";
    }else{
      this.roleAdmin = role;
    }
    if(idAdmin != null && role != null){
      this.alertSos();
    }

    // *******************************animation
    // setInterval(() => {
    //   this.setupInter();

    // }, 2000)

  }
  // *******************************animation
  // setupInter() {
  //   this.animationItem = !this.animationItem;
  // }
  alertSos() {
    this.websocketService.ngOnInit();
  }

  checkActiveMenu(url: string) {
    if (url.includes('/watch-status') || url.includes('/watch-management')) {
      this.activeMenu = 'watches';
    } else if (url.includes('/admin-list') || url.includes('/admin-filter-list') || url.includes('/admin-view-only') || url.includes('/whitelist')) {
      this.activeMenu = 'roles';
    } else if (url.includes('/ReportUser') || url.includes('/ReportJob') || url.includes('/ReportOperator') || url.includes('/daily-report')) {
      this.activeMenu = 'reports';
    } else if (url.includes('/liveMonitor') || url.includes('/liveMonitorWorking') || url.includes('/liveMonitorSuccess')) {
      this.activeMenu = 'sos';
    } else {
      this.activeMenu = '';
    }
  }

  toggleMenu(menu: string) {
    if (this.activeMenu === menu) {
      this.activeMenu = '';
    } else {
      this.activeMenu = menu;
    }
  }

  loadAdmin(adminId: string) {
    var res:any;
    var Province:any;
    var District:any;
    var SubDistrict:any;

    this.ServiceService.GetAdmin_(adminId).then(data_ => {
      // console.log("data_ ",data_);

      res = data_;

      this.dataAdminLoginin = res;
      this.idOperator = res[0].adminId;
      this.operatorId = res[0].adminId;
      this.adminNamerole = res[0].adminNamerole;
      this.adminDistrictId = res[0].adminDistrictId;
      this.adminParrent = res[0].adminParrent;
      this.adminProvinceID = res[0].adminProvinceId;
      if (res[0].adminRole == "AdminProvince") {
        this.ServiceService.GetProvinceByName_(res[0].adminProvinceId).then(dataProvince => {
          Province = dataProvince;
          this.getNameProvince = Province[0].provinceName;
        });
      } else if (res[0].adminRole == "AdminDistrict") {
        this.ServiceService.GetProvinceByName_(res[0].adminProvinceId).then(dataProvince => {
          Province = dataProvince;
          this.getNameProvince = Province[0].provinceName;
          this.getProvinceId = Province[0].provinceId;
        });
        this.ServiceService.GetDistrictByName_(res[0].adminDistrictId).then(dataDistrict => {
          District = dataDistrict;
          this.getNameDistrict = District[0].amphurName;
        });
      } else if (res[0].adminRole == "AdminSubdistricts") {
        this.ServiceService.GetProvinceByName_(res[0].adminProvinceId).then(dataProvince => {
          Province = dataProvince;
          this.getNameProvince = Province[0].provinceName;
        });
        this.ServiceService.GetDistrictByName_(res[0].adminDistrictId).then(dataDistrict => {
          District = dataDistrict;
          this.getNameDistrict = District[0].amphurName;
        });
        this.ServiceService.GetSubdistrictByName_(res[0].adminSubDistrictId).then(dataSubDistrict => {
          SubDistrict = dataSubDistrict;

          this.getNameSubDistrict = SubDistrict[0].districtName;
          this.getIdSubDistrict = SubDistrict[0].districtId;
        });
      }
    });

    var data:any
    this.ServiceService.GetIdOperatorByIdOperatorAdmin_(adminId).then(resDataOperator => {
      // console.log("........ ",resDataOperator);

      data = resDataOperator;
      if(data.length > 0) {
        this.dataOperator = data[0];
        localStorage.setItem('nameOpertor', data[0].operatorUsername);
        // console.log(" ------ ",data);

      }
    })
  }

  checkMenu(type: any) {
    if (type == "SuperAdmin") {
      this.showHeadSuperAdmin = true;
      this.hideLiveMonitor = true;
      this.hideReport = true;
      this.showAdminFilter = true;
      this.showAdminViewOnly = true;
      this.showWatchStatus = true;
      this.showWatchManagement = true;
    } else if (type == "AdminViewOnly") {
      this.showHeadSuperAdmin = true;
      this.hideLiveMonitor = true;
      this.hideReport = true;
      this.showAdminFilter = true;
      this.showAdminViewOnly = true;
      this.showWatchStatus = true;
    } else if (type == "AdminProvince") {
      this.showHeadAdminProvince = true;
      this.hideLiveMonitor = true;
      this.hideReport = true;
      this.showPdf = true;
    } else if (type == "AdminDistrict") {
      this.showHeadAdminDistrict = true;
      this.hideLiveMonitor = true;
      this.showPdf = true;
    } else if (type == "AdminSubdistricts") {
      this.showHeadAdminSubdistricts = true;
      this.hideLiveMonitor = true;
      this.showPdf = true;
    } else if (type == "Operator") {
      this.showProfileOperator = true;
      this.hideLiveMonitor = false;
      this.hideAlertOperator = true;
      this.showWatchManagement = true;
      this.showTrackingSystem = true;
    } else if (type == "Filter") {
      this.hideLiveMonitor = false;
      this.showHeadAdminHuawei = false;
      this.hideReport = false;
      this.showPdf = false;
      this.showSmartwatchTicker = true;
    } else {
      this.hideLiveMonitor = true;
      this.showHeadAdminHuawei = true;
      this.hideReport = true;
      this.showPdf = true;
      this.showWatchStatus = true;
      this.showWatchManagement = true;
    }
  }

  loadSos(idOperator:string){
    this.ServiceService.loadSOS_(idOperator).then(res => {

    })
  }

  openDialogEditAdmin(): void {
    const dialogRef = this.dialog.open(EditAdminDialog, {
      data: { idAdmin: this.adminId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed > ? : ', result);
    });
  }
}
